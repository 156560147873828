import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import FounderImageFirst from "../../../assets/drem.png";
import FounderImageSecond from "../../../assets/drem1.png";
import FounderImageThired from "../../../assets/drem2.png";
import FounderImageFourt from "../../../assets/drem3.png";
import righta from "../../../assets/lefta.png";
import lefta from "../../../assets/righta.png";
import like from "../../../assets/like.svg";
import fullRedHeart from "../../../assets/fullRedHeart.png";
import heart from "../../../assets/heart_icon.png";
import location from "../../../assets/location.svg";
import icon from "../../../assets/icon.svg";
import icon1 from "../../../assets/icon1.svg";
import icon2 from "../../../assets/icon2.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import axiosInstance from "../../../apiInstances/axiosInstance";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";
import { useNavigate } from "react-router-dom";
import axiosInstanceAuth from "../../../apiInstances/axiosInstanceAuth";
import HousePropertySlider from "./HousePropertySlider";

const Housecart = () => {
  const navigate = useNavigate();
  const [likeFlag, setLikeFlag] = useState(false);
  const [savePost, setSavedPost] = useState([]);
  const [clientSliderData, setClientSliderData] = useState([]);

  const savedProperty = () => {
    axiosInstanceAuth
      .post("/savedProperty", {
        status: "",
      })
      .then((res) => {
        setSavedPost(res?.data?.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const newProperty = () => {
    axiosInstance
      .get(`/propaties`)

      .then((res) => {
        const myData = res?.data?.data;
        const arrData = myData?.map((e, i) => {
          return {
            id: e?._id,
            clientImage: e?.image,
            head: e?.heading,
            like: true,
            locations: location,
            place: e?.address,
            discription: e?.discription,
            iconWithCount: [
              {
                icon: icon,
                count: e?.Bedrooms,
              },
              {
                icon: icon1,
                count: e?.toilets,
              },
              {
                icon: icon2,
                count: e?.Bathrooms,
              },
            ],
          };
        });

        setClientSliderData(arrData);
        //console.log("🚀 ~ .then ~ arrData:", arrData);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    savedProperty();
    newProperty();
  }, []);

  const savedLocalPost = localStorage.getItem("Saved")?.split(",");
  return (
    <>
      <div className="my-8">
        <div className="flex justify-between lg:mx-5">
          <h2 className="font-semibold xl:text-3xl lg:text-2xl  md:text-xl text-lg text-[#262626]">
            New Properties for Sale
          </h2>
          <div className="flex my-auto mb-auto gap-2">
            <div>
              <img className="prev cursor-pointer w-10" src={righta} alt="" />
            </div>
            <div>
              <img className="next cursor-pointer w-10" src={lefta} alt="" />
            </div>
          </div>
        </div>
      
      </div>
      <div className="flex justify-center lg:mx-5">
        <Swiper
          loop={false}
          spaceBetween={20}
          slidesPerView={1}
          modules={[Navigation]}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            // 1080: {
            //   slidesPerView: 4,
            //   spaceBetween: 30,
            // },
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1460: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          className="w-[100%]"
        // onSwiper={(swiper) => //console.log(swiper)}
        // onSlideChange={() => //console.log("slide change")}
        >
          <div className="grid place-content-center ">
            {clientSliderData.map((e, index) => (
              <SwiperSlide key={index}>
                <HousePropertySlider e={e} savedLocalPost={savedLocalPost} />
              </SwiperSlide>
            ))}
            {clientSliderData.length > 10 ?
              <SwiperSlide key="show-more">
                <div className="flex justify-start items-center h-[100%] px-3">
                  <div className="bg-[#ffd9e2] p-3 rounded-full cursor-pointer flex justify-center items-center" onClick={() => navigate('/buy')}>
                    <p className=" text-sm pe-2">See More</p>
                    <img src={lefta} alt="more" className="w-[30px]" />
                  </div>
                </div>
              </SwiperSlide>
              : null
            }
          </div>
        </Swiper>
      </div >
      <div></div>
    </>
  );
};

export default Housecart;
