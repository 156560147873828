import { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const ReadMoreText = ({ text, line=2, color='#333F48' }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="relative">
            <p
                className={`text-base font-light text-[#333F48] transition-all duration-300 ${isExpanded ? "line-clamp-none" : `line-clamp-${line}`
                    }`}
            >
                {text}
            </p>

            <button
                className={`flex items-center gap-1 text-sm italic font-medium text-[${color}] hover:underline ml-1`}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                Read more  <MdOutlineArrowBackIosNew size={16} className={` ${isExpanded ? ' rotate-90' : "-rotate-90"}`} />
            </button>

        </div>
    );
};

export default ReadMoreText;
