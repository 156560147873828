import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import logoSmall from "../../assets/logo-new.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Profile from "../../assets/Profile.png";

const NavBarAlternative = () => {
    const getPath = useLocation().pathname.split("/")?.[1];
    const [showMenu, setShowMenu] = useState(false);
    const [navbar, setNavbar] = useState(true);

    const navigate = useNavigate();

    const checkAuth = localStorage.getItem("Token");
    const headerData = [
        {
            id: 0,
            pathname: "/buy",
            pagename: "Buy",
        },
        {
            id: 1,
            pathname: "/rent",
            pagename: "Rent",
        },
        {
            id: 2,
            pathname: "/sold",
            pagename: "Sold",
        },
        {
            id: 3,
            pathname: "/new-homes",
            pagename: "New homes",
        },
        {
            id: 4,
            pathname: "/find-agents",
            pagename: "Find agents",
        },
        {
            id: 5,
            pathname: "/blogs",
            pagename: "Blogs",
        },
        {
            id: 6,
            pathname: "/commercial",
            pagename: "Commercial",
        },
    ];

    const shoeMenuList = () => {
        return (
            <div className='max-w-6xl mx-auto mt-2 flex w-full items-center justify-between mb-3'>
                <ul className="menuitems-subpages flex flex-col justify-start sm:justify-center absolute sm:static sm:flex-row px-5 w-6/12 sm:w-full h-full sm:h-auto top-20 gap-8 bg-white z-[999]">
                    {headerData?.map((data, index) => (
                        <div key={index + 1}>
                            <li className="min-w-max ">
                                <Link to={data.pathname}>
                                    <div className={`${`/${getPath}` === data.pathname && "font-bold "} lg:!flex !justify-center !items-center text-[#000000] rounded-3xl  hover:font-semibold hover:text-[#404040]`}>
                                        {data.pagename}
                                    </div>
                                </Link>
                            </li>
                        </div>
                    ))}
                </ul>
            </div>
        )
    }

    return (
        <>
            <div className="max-w-6xl mx-auto px-4 py-3 flex items-center justify-between sticky z-[99999]">
                <div className="flex items-center gap-3">
                    <div
                        className="text-2xl cursor-pointer"
                        onClick={() => { setNavbar(!navbar); setShowMenu(!showMenu); }}
                    >
                        {navbar ? <FaBars /> : <>&#10006;</>}
                    </div>
                    <span className="text-sm md:text-base font-medium">Menu</span>
                </div>
                <div className="flex justify-center" onClick={() => navigate("/")}>
                    <img
                        src={logoSmall}
                        alt="logo"
                        className="md:h-[25px] h-[20px] rounded-xl cursor-pointer"
                    />
                </div>
                <div className="flex items-center gap-3 md:gap-5">
                    {checkAuth ? (
                        <img
                            src={Profile}
                            alt="profile"
                            className="h-8 w-8 md:h-10 md:w-10 rounded-full cursor-pointer"
                            onClick={() => navigate("/dashboard")}
                        />
                    ) : (
                        <>
                            <button
                                className="text-sm md:text-base font-medium hover:text-gray-600"
                                onClick={() => navigate("/log-in")}
                            >
                                Sign in
                            </button>
                            <button
                                className="py-2 px-4 md:px-5 rounded-lg border bg-[#E5002A] text-white hover:bg-white hover:text-[#E5002A] hover:border-[#E5002A] font-medium text-sm md:text-base"
                                onClick={() => navigate("/sign-up")}
                            >
                                Join
                            </button>
                        </>
                    )}
                </div>
            </div >
            {
                showMenu ? shoeMenuList() : ""
            }
        </>
    )
}

export default NavBarAlternative