import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import HeaderImg from "../../../assets/findAgent/headerImg.webp"

const AgencyContactCard = ({ AgencyData }) => {
  const [Next, setNext] = useState(true);
  const [whichActive, setWhichActive] = useState("Selling a property");
  const ActiveTab = "";
  return (
    <div className="bg-white md:rounded-xl" style={{ boxShadow: "0px 1px 4px 0px #00000029" }}>
      <div className={`${AgencyData?.primary_color
        ? AgencyData?.primary_color.includes('#') ? `bg-[${AgencyData.primary_color}]` : `bg-${AgencyData.primary_color}-700`
        : `bg-[#FFE512]`} px-6 py-2 flex justify-center md:rounded-t-xl`}>
        <img src={AgencyData?.agencyLargeLogo || HeaderImg} alt="Red white" className="h-8" />
      </div>
      <div className="p-4">
        <h3 className="text-xl font-normal text-[#3D3B40]">Contact {AgencyData?.principal_name}</h3>
        <div>
          <div className="flex lg:items-center flex-row md:flex-col lg:flex-row gap-1 text-sm lg:text-base text-[#3D3B40] font-normal">
            <span className=" flex gap-1"> <FaStar className="text-[#FFB200] text-sm" /> 5.0 </span>
            <span className=" ">(1514 review) </span>
          </div>
          <h3 className="text-base font-light text-[#3D3B40] mt-6 mb-5">What's your enquiry about?</h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <button
              className={`border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] ${whichActive === "Selling a property" ? ActiveTab : null
                }`}
              onClick={() => setWhichActive("Selling a property")}
            >
              Selling a property
            </button>
            <button
              className={`border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] ${whichActive === "An advertised property" ? ActiveTab : null
                }`}
              onClick={() => setWhichActive("An advertised property")}
            >
              An advertised property
            </button>
            <button
              className={`border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] ${whichActive === "Property management" ? ActiveTab : null
                }`}
              onClick={() => setWhichActive("Property management")}
            >
              Property management
            </button>
            <button
              className={`border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] ${whichActive === "General enquiry" ? ActiveTab : null
                }`}
              onClick={() => setWhichActive("General enquiry")}
            >
              General enquiry
            </button>
          </div>
          <button
            className={`border border-[#F6F5F7] rounded-lg text-base px-6 py-3 font-light text-[#BEBAC2] w-full mt-5 bg-[#F6F5F7] }`}
            onClick={() => setNext(false)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgencyContactCard;
