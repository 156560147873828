import React, { useEffect } from "react";
import { usePagination, DOTS } from "../hooks/usePagination";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const PaginationNormal = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) => {
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentPage]);

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => onPageChange(currentPage + 1);
    const onPrevious = () => onPageChange(currentPage - 1);
    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="bg-white w-full border-b-[3px] border-[#E9EBED]">
            <div className="flex justify-center items-center gap-1 py-3 text-[#333F48] text-base font-base">
                {currentPage !== 1 && (
                    <button
                        className="disabled:opacity-50"
                        disabled={currentPage === 1}
                        onClick={onPrevious}
                    >
                        <MdKeyboardArrowLeft />
                    </button>
                )}
                <span className="text-sm">Page {currentPage} of {lastPage}</span>
                <button
                    className="disabled:opacity-50"
                    disabled={currentPage === lastPage}
                    onClick={onNext}
                >
                    <MdKeyboardArrowRight size={16} />
                </button>
            </div>
        </div>
    );
};

export default PaginationNormal;

