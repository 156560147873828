import React from 'react'
import { useLocation } from 'react-router-dom';
import NavbarPrimary from './NavbarPrimary';
import NavbarAlternative from './NavBarAlternative';


const NavBar = () => {
  const getPath = useLocation().pathname;

  return (
    <>
      {getPath === "/" ? (
        <NavbarPrimary />
      ) : (
        <NavbarAlternative />
      )
      }
    </>
  )
}

export default NavBar