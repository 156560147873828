import React, { useState } from 'react'
import { SlLocationPin } from "react-icons/sl";

export const OurTopMarkets = () => {

    const [activeTab, setActiveTab] = useState("sold");

    const marketData = {
        sold: [
            { location: "Pimpama, QLD, 4209", properties: 172 },
            { location: "Upper Coomera, QLD, 4209", properties: 147 },
            { location: "Coomera, QLD, 4209", properties: 67 },
            { location: "Pacific Pines, QLD, 4211", properties: 64 },
            { location: "Oxenford, QLD, 4210", properties: 29 },
            { location: "Ormeau, QLD, 4208", properties: 16 },
            { location: "Hope Island, QLD, 4212", properties: 14 },
            { location: "Helensvale, QLD, 4212", properties: 12 },
            { location: "Arundel, QLD, 4214", properties: 10 },
            { location: "Southport, QLD, 4215", properties: 8 },
        ],
        leased: [
            { location: "Upper Coomera, QLD, 4209", properties: 132 },
            { location: "Pimpama, QLD, 4209", properties: 118 },
            { location: "Pacific Pines, QLD, 4211", properties: 59 },
            { location: "Coomera, QLD, 4209", properties: 52 },
            { location: "Oxenford, QLD, 4210", properties: 27 },
            { location: "Ormeau, QLD, 4208", properties: 21 },
            { location: "Helensvale, QLD, 4212", properties: 18 },
            { location: "Hope Island, QLD, 4212", properties: 15 },
            { location: "Arundel, QLD, 4214", properties: 12 },
            { location: "Southport, QLD, 4215", properties: 9 },
        ],
    }

    const [showAll, setShowAll] = useState(false)
    const displayedMarkets = showAll ? marketData[activeTab] : marketData[activeTab].slice(0, 6)

    return (
        <>
            <div className=" p-6 bg-white md:rounded-xl shadow-[0px_1px_4px_0px_rgba(0,0,0,0.2)]">
                <h2 className="text-xl font-normal text-[#3D3B40]">Our top markets</h2>
                <p className="text-[#3D3B40] mt-1 mb-4 text-sm font-normal">Our top suburbs for sold and leased properties in the last 12 months.</p>

                <div className="flex mb-6">
                    <div className="inline-flex rounded-full border border-gray-[#959199] ">
                        <button
                            onClick={() => setActiveTab("sold")}
                            className={`font-light px-6 py-2 rounded-full text-base transition-colors ${activeTab === "sold" ? "border-[#3D3B40] border-[0.5px]" : "hover:border-[0.5px] hover:border-gray"
                                }`}
                        >
                            Sold
                        </button>
                        <button
                            onClick={() => setActiveTab("leased")}
                            className={`font-light px-6 py-2 rounded-full text-base transition-colors ${activeTab === "leased" ? "border-[#3D3B40] border-[0.5px]" : "hover:border-[0.5px] hover:border-gray"
                                }`}
                        >
                            Leased
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {displayedMarkets.map((market, index) => (
                        <div key={index} className="text-base border border-gray-[#E5E3E8] text-[#3D3B40] rounded-lg p-4 flex items-start">
                            <SlLocationPin className="w-5 h-5  mt-1 mr-2 flex-shrink-0" />
                            <div>
                                <p className='mb-1'>{market.location}</p>
                                <p>{market.properties} properties</p>
                            </div>
                        </div>
                    ))}
                </div>

                {/* {!showAll && marketData[activeTab].length > 6 && ( */}
                <div className="mt-4 flex justify-center">
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="px-8 py-2 border border-gray-[#959199] rounded-lg text-[#3D3B40] hover:bg-gray-50 transition-colors"
                    >
                        {!showAll ? `Show ${marketData[activeTab].length - 6} more` : "show less"}
                    </button>
                </div>
                {/* )} */}
            </div>
        </>
    )
}