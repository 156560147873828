import React, { useEffect } from "react";
import { usePagination, DOTS } from "../hooks/usePagination";
import { MdKeyboardArrowRight } from "react-icons/md";

const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) => {
  const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [currentPage]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);
  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center space-x-4">
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <span key={index} className="text-gray-500">&#8230;</span>;
          }

          return (
            <button
              key={index}
              className={`py-3 border rounded-lg text-center w-10 h-10 text-sm ${pageNumber === currentPage ? "bg-[#E4002B] text-white" : "border-gray-300 text-[#3D3B40] hover:bg-gray-100"}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          className="flex items-center px-4 py-2 gap-1 text-base font-light text-white bg-[#E4002B] rounded-lg hover:bg-red-700 disabled:opacity-50"
          disabled={currentPage === lastPage}
          onClick={onNext}
        >
          Next <MdKeyboardArrowRight />
        </button>
      </div>
      <p className="mt-5 text-sm text-[#726E75] ">Viewing {(currentPage - 1) * pageSize + 1}-{Math.min(currentPage * pageSize, totalCount)} of {totalCount} properties</p>
    </div>
  );
};

export default Pagination;
