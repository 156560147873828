import React, { useEffect } from "react";
import Layout1 from "../../Layouts/Layout1";
import { Link } from "react-router-dom";
import { usePropertyData } from "../../context/PropertyDataContext";
import { DateFormat } from "../common/utils/getTimeAgo";

const SkeletonLoader = () => (
    <div className="flex flex-col rounded-lg bg-white shadow animate-pulse">
        <div className="w-full h-48 bg-gray-300 rounded-t-lg"></div>
        <div className="mb-3 flex w-full flex-col justify-between px-4 pt-2 h-[200px]">
            <div className="flex flex-col justify-between mb-2">
                <div className="h-6 bg-gray-300 w-3/4 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-full mt-2 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-5/6 mt-2 rounded-md"></div>
            </div>
            <div className="h-8 bg-gray-300 w-1/3 mt-auto rounded-md"></div>
        </div>
    </div>
);

const Blogs = () => {
    const { fetchBlogs, blogCache } = usePropertyData();

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <Layout1>
            <div className="px-5 pt-6">
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold text-center mb-8">Latest Blogs</h2>
                    <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 pb-4">
                        {blogCache.length === 0
                            ? Array.from({ length: 3 }).map((_, index) => <SkeletonLoader key={index} />)
                            : blogCache.map((blog) => (
                                <div key={blog._id} className="flex flex-col rounded-lg bg-white shadow">
                                    <img
                                        src={blog.coverImage || "/test.jpg"}
                                        alt={blog.title}
                                        className="w-full h-48 object-cover rounded-t-lg"
                                    />
                                    <div className="mb-3 flex w-full flex-col justify-between px-4 pt-2 text-start h-[200px]">
                                        <div className="flex flex-col justify-between mb-2">
                                            <h3 className="text-xl font-semibold text-gray-900">
                                                {blog.title}
                                            </h3>
                                            <p className="text-gray-600 mt-2 flex-grow line-clamp-2">
                                                {blog?.description}
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                {DateFormat(blog.updatedAt)}
                                            </p>
                                        </div>
                                        <div className="mt-auto">
                                            <Link to={`/blogs/${blog._id}`}>
                                                <button className="px-4 py-2 text-sm font-medium text-white bg-[#e5002a] rounded hover:bg-[#e5002a] transition-all duration-300 ease-in-out">
                                                    Read More
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </Layout1>
    );
};

export default Blogs;
