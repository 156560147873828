import React from "react";
import { Footer, NavBar } from "../components";

const Layout1 = ({ children }) => {
  return (
    <div className="bg-[#f6f5f7]">

      {children}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout1;

