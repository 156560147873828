import React, { useEffect, useState } from "react";
import OverviewCard from "../common/OverviewCard/OverviewCard";
import AboutCard from "../common/AboutCard/AboutCard";
import ReviewsCard from "../common/ReviewsCard/ReviewsCard";
import ProformanceCard from "../common/ProformanceCard/ProformanceCard";
import PropertiesCard from "../common/PropertiesCard/PropertiesCard";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import email from "../../assets/message_icon.png";
import mailWhite from "../../assets/mailWhite.png";
import call from "../../assets/call_icon.png";
import AgencyConnectCard from "../common/AgencyConnectCard/AgencyConnectCard";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import HeaderImg from "../../assets/findAgent/headerImg.webp"
import VideoImg from "../../assets/findAgent/videoImg1.png"
import { MdIosShare } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { OurTopMarkets } from "../FindAgent/OurTopMarkets";
import AgencyContactCard from "../common/AgencyContactCard/AgencyContactCard";

const AgencyProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [AgencyData, setAgencyData] = useState({});
  const [AgentsInfo, setAgentsInfo] = useState({});

  const [property, setProperty] = useState({});

  let reviewlength = 0;
  let startcount = 0;
  if (AgentsInfo.length > 0) {
    for (let d of AgentsInfo) {
      if (d.reviews.length > 0) {
        reviewlength += d.reviews.length;
        for (let i = 0; i < d.reviews.length; i++) {
          startcount += Number(d.reviews[i].star);
        }
      }
    }
  }
  let totalaverage = startcount / reviewlength;

  useEffect(() => {
    GetAgentData(id);
    GetAllAgentData(id);
  }, []);

  const GetAgentData = async (id) => {
    await axiosInstanceAuth
      .post(`agency/ViewProfile_U`, {
        id: id,
      })
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          setAgencyData(mydata);
          setProperty(res?.data?.data?.[0]?.properties);
        } else {
        }
      })
      .catch((err) => {
        //console.log("err --->", err);
      });
  };

  const GetAllAgentData = async (id) => {
    await axiosInstanceAuth
      .post("Agency_Agent/viewAllAgentsOfAgency_U", {
        id: id,
      })
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          setAgentsInfo(mydata);
        } else {
        }
      })
      .catch((err) => {
        //console.log("err --->", err);
      });
  };

  const [isActive, setisActive] = useState("Overview");

  const ActiveTab =
    " border-b-[#E5002A] rounded-t-lg text-[#404040] font-semibold";
  const NormalTab =
    "w-[20%] grid place-items-center text-[#404040] font-normal text-sm md:text-md lg:text-base border border-b-2 border-transparent hover:border-b-[#E5002A] py-3 px-[18px] ease-in-out duration-700 cursor-pointer";

  const tabData = [
    { id: 1, title: "Overview" },
    { id: 2, title: "Properties" },
    { id: 3, title: "Performance" },
    { id: 4, title: "About" },
    { id: 5, title: "Reviews" },
    { id: 6, title: "Contact" },
  ];
  const handleClick = (e) => {
    document.querySelector(`#${e}`).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const ReviewTags = [
    "Professional (328)",
    "Great communicator (271)",
    "Genuine (268)",
  ];

  return (
    <>
      <div className="">
        <div className={`${AgencyData[0]?.primary_color
          ? AgencyData[0]?.primary_color.includes('#') ? `bg-[${AgencyData[0]?.primary_color}]` : `bg-${AgencyData[0]?.primary_color}-700`
          : `bg-[#FFE512]`} px-6 py-4 flex justify-center`}>
          <img src={AgencyData[0]?.agencyLargeLogo || HeaderImg} alt="Red white" className="h-8 " />
        </div>
        <div className="">
          <img src={AgencyData[0]?.heroImg || VideoImg} alt="Red white" className="w-full h-[200px] md:h-[400px] object-cover" />
        </div>

        <div className="max-w-6xl mx-auto">

          <div className="bg-white p-3 mb-2 md:mt-6 flex flex-col md:flex-row justify-between md:items-center">
            <div className="flex flex-col gap-3">
              <div>
                <div className="text-[#3D3B40] font-normal text-2xl flex gap-6 items-center">
                  {AgencyData[0]?.principal_name}
                  <MdIosShare size={16} />
                </div>
                <div className="text-[#3D3B40] font-light text-base flex gap-[3px]">
                  <p>Selling Principal at </p>{" "}
                  <p className="text-[#726E75]"> Ray White Alliance</p>
                </div>
                <div className="flex lg:items-center flex-row md:flex-col lg:flex-row gap-1 text-sm lg:text-base text-[#3D3B40] font-normal">
                  <span className="flex gap-1"> <FaStar className="text-[#FFB200] text-sm" /> 5.0 </span>
                  <span className="">(406 review) </span>
                </div>
                <div className="flex  items-center gap-2 mt-[18px]">
                  <div className="">
                    <AvatarGroup total={AgentsInfo?.length}>
                      {AgentsInfo?.length > 0 &&
                        AgentsInfo?.map((i, index) => (
                          <Avatar
                            key={index}
                            alt=""
                            src={i?.profileImg}
                            className="aspect-square rounded-full"
                          />
                        ))}
                    </AvatarGroup>
                  </div>
                  <div className="text-[#726E75] font-light text-base">
                    {AgentsInfo?.length} people work here
                  </div>
                </div>
                <div className="flex flex-wrap justify-start items-center gap-2 mt-3">
                  {ReviewTags?.length > 0 &&
                    ReviewTags?.map((i, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-center bg-[#F5F5F5] rounded font-normal text-[#3D3B40] text-sm py-1 px-2"
                      >
                        {i}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* button */}
            <div className="flex flex-col gap-4 mt-4 w-full md:max-w-[320px]" >
              <button
                className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg bg-[#E4002B] py-3 px-2 cursor-pointer text-white text-center"
                onClick={() => {
                  navigate(`/appraisal/${AgencyData?._id}`);
                }}
              >
                <img
                  src={mailWhite}
                  alt="icon"
                  className=""
                />
                <span>Request a free appraisal</span>
              </button>

              <div className="flex gap-3">
                <button
                  className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg border border-[#959199] text-[#3D3B40] py-3 px-2 cursor-pointer text- text-center"
                // onClick={() =>
                //   scrollToDiv()
                // }
                >
                  <img src={email} alt="icon" className="w-4 cursor-pointer" />
                  <span>Enquire</span>
                </button>

                <button
                  className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg border border-[#959199] text-[#3D3B40] py-3 px-2 cursor-pointer text- text-center"
                // onClick={() => setCalls(!calls)}
                >
                  <img src={call} alt="icon" className="w-4 cursor-pointer" />

                  <span className="">Call</span>

                </button>
              </div>
            </div>
          </div>


          <div className="flex gap-[60px] mb-10">
            <div className="w-full grid col-span-1 xl:col-span-7">
              <div
                id="navigation"
                className="w-full grid place-items-center overflow-x-scroll rounded-xl scrollBarHidden"
              >
                <div className="w-full bg-[#FFFFFF] rounded-xl">
                  <div className="flex justify-between items-center border border-b-2 border-transparent">
                    {tabData.map((tab) => (
                      <div
                        key={tab.id}
                        className={`${NormalTab} ${isActive === tab.title ? ActiveTab : ""
                          }`}
                        onClick={() => {
                          handleClick(tab.title);
                          setisActive(tab.title);
                        }}
                      >
                        {tab.title}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* ------ Overview ------ */}
              <div id="Overview" className="mt-4 md:mt-6">
                <OverviewCard AgencyData={AgencyData} />
              </div>

              {/* ------ Properties ------ */}
              <div id="Properties" className="mt-4 md:mt-8">
                {/* <AgentPropertiesCard property={property} AgencyData={AgencyData}/> */}
                {/* temporary comment the component */}
                <PropertiesCard property={property} AgencyData={AgencyData} />
              </div>

              {/* ------ Performance ------ */}
              <div id="Performance" className="mt-4 md:mt-8">
                <ProformanceCard />
              </div>

              <div className="mt-4 md:mt-8">
                <OurTopMarkets />
              </div>

              {/* <div className="xl:hidden mt-4 md:mt-8">
                <AgencyConnectCard />
              </div> */}

              {/* ------ About ------ */}
              <div id="About" className="mt-4 md:mt-8">
                <AboutCard AgentsInfo={AgentsInfo} />
              </div>

              {/* ------ Reviews ------ */}
              <div id="Reviews" className="mt-4 md:mt-8">
                <ReviewsCard
                  totalaverage={totalaverage}
                  reviewlength={reviewlength}
                />
              </div>

              {/* ------ Contact ------ */}
              <div id="Contact" className="mt-4 md:mt-8">

                <AgencyContactCard AgencyData={AgencyData[0]} />

                {/* <ContactCard
                  totalaverage={totalaverage}
                  reviewlength={reviewlength}
                  AgencyData={AgencyData}
                /> */}

                <div className="w-full grid col-span-1 xl:col-span-5 text-[#3D3B40] text-sm mt-5 px-1">
                  {`^ Agent performance snapshot data & property lists include all
                  properties ${AgencyData.name} has sold (last 12 months) as lead and
                  secondary agent and published on realestate.com.au. It may not
                  contain off-market and private sales, properties with unknown sold
                  dates, sales while at another agency and sales that may be
                  exclusively listed on other websites. Please contact 
                  ${AgencyData.name} for their full sales history.`}
                </div>

              </div>
            </div>
            {/* ------ Right Part ------ */}
            <div className="hidden xl:block mt-[74px] ">
              <AgencyConnectCard
                totalaverage={totalaverage}
                reviewlength={reviewlength}
                handleClick={handleClick}
                AgencyData={AgencyData}
              />
            </div>
          </div>
        </div>

      </div >
    </>
  );
};

export default AgencyProfile;
