import { useState } from "react";

const TabData = [
    "Real estate",
    "New homes",
    "Popular areas",
    "Popular searches",
];

const tabContent = {
    "Properties for sales": (
        <>
            <h3 className="font-light my-6 !text-[#3D3B40]">Real estate in Australia</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[#726E75]">
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Real estate NSW</p>
                    <p className="underline cursor-pointer">Real estate WA</p>
                    <p className="underline cursor-pointer">Real estate ACT</p>
                    <p className="underline cursor-pointer">Real estate Melbourne</p>
                    <p className="underline cursor-pointer">Real estate Adelaide</p>
                    <p className="underline cursor-pointer">Real estate Darwin</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Real estate VIC</p>
                    <p className="underline cursor-pointer">Real estate SA</p>
                    <p className="underline cursor-pointer">Real estate NT</p>
                    <p className="underline cursor-pointer">Real estate Brisbane</p>
                    <p className="underline cursor-pointer">Real estate Hobart</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Real estate QLD</p>
                    <p className="underline cursor-pointer">Real estate TAS</p>
                    <p className="underline cursor-pointer">Real estate Sydney</p>
                    <p className="underline cursor-pointer">Real estate Perth</p>
                    <p className="underline cursor-pointer">Real estate Canberra</p>
                </div>
            </div>
        </>
    ),
    "New homes": (
        <>
            <h3 className="font-light my-6 !text-[#3D3B40]">Build new homes in Australia</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[#726E75]">
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Home builders</p>
                    <p className="underline cursor-pointer">House and land</p>
                    <p className="underline cursor-pointer">Home builders Perth</p>
                    <p className="underline cursor-pointer">Home builders Adelaide</p>
                    <p className="underline cursor-pointer">House and land packages Sydney</p>
                    <p className="underline cursor-pointer">House and land packages Canberra</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">House designs</p>
                    <p className="underline cursor-pointer">Land estates</p>
                    <p className="underline cursor-pointer">Home builders Sydney</p>
                    <p className="underline cursor-pointer">House and land packages Melbourne</p>
                    <p className="underline cursor-pointer">House and land packages Brisbane</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">New apartments</p>
                    <p className="underline cursor-pointer">Home builders Melbourne</p>
                    <p className="underline cursor-pointer">Home builders Brisbane</p>
                    <p className="underline cursor-pointer">House and land packages Perth</p>
                    <p className="underline cursor-pointer">House and land packages Adelaide</p>
                </div>
            </div>
        </>
    ),
    "Popular areas": (
        <>
            <h3 className="font-light my-6 !text-[#3D3B40]">Browse popular areas in Australia</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[#726E75]">
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Sydney house prices</p>
                    <p className="underline cursor-pointer">Brisbane house prices</p>
                    <p className="underline cursor-pointer">Canberra house prices</p>

                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Perth house prices</p>
                    <p className="underline cursor-pointer">Adelaide house prices</p>
                    <p className="underline cursor-pointer">Suburb profiles</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Melbourne house prices</p>
                    <p className="underline cursor-pointer">Hobart house prices</p>

                </div>
            </div>
        </>
    ),
    "Popular searches": (
        <>
            <h3 className="font-light my-6 !text-[#3D3B40]">Browse popular property searches</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[#726E75]">
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Property news</p>
                    <p className="underline cursor-pointer">Mortgage calculator</p>
                    <p className="underline cursor-pointer">Selling property</p>
                    <p className="underline cursor-pointer">Property guides</p>
                    <p className="underline cursor-pointer">Rent to buy</p>
                    <p className="underline cursor-pointer">Auction results</p>

                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Commercial property news</p>
                    <p className="underline cursor-pointer">Capital gains tax Australia</p>
                    <p className="underline cursor-pointer">Negative gearing</p>
                    <p className="underline cursor-pointer">How much is my house worth</p>
                    <p className="underline cursor-pointer">How much does it cost to build a house</p>
                </div>
                <div className="flex flex-col gap-4">
                    <p className="underline cursor-pointer">Stamp duty calculator</p>
                    <p className="underline cursor-pointer">Renovation</p>
                    <p className="underline cursor-pointer">How much can I borrow</p>
                    <p className="underline cursor-pointer">The block</p>
                    <p className="underline cursor-pointer">Australia housing market</p>

                </div>
            </div>
        </>),
};


const tabImages = {
    "Properties for sale": [
        { title: "Retirement villages & retirement living for sale", },
        { title: "Townhouses for sale", },
        { title: "Rural properties for sale", },
        { title: "Houses for sale", },
        { title: "Acreage for sale", },
        { title: "Villas for sale", },
        { title: "Land for sale", },
        { title: "Apartments & units for sale", },
        { title: "Blocks of units for sale", }
    ],
    "New homes": [
        { title: "Home builders", },
        { title: "House and land", },
        { title: "Home builders Perth", },
        { title: "Home builders Adelaide", },
        { title: "House and land packages Sydney", },
        { title: "House and land packages Canberra", }
    ],
    "Popular areas": [
        { title: "Sydney house prices", },
        { title: "Brisbane house prices", },
        { title: "Canberra house prices", },
        { title: "Perth house prices", },
        { title: "Adelaide house prices", },
        { title: "Suburb profiles", }
    ],
    "Popular searches": [
        { title: "Property news", },
        { title: "Mortgage calculator", },
        { title: "Selling property", },
        { title: "Property guides", },
        { title: "Rent to buy", },
        { title: "Auction results", }
    ]
};


const FooterTabbingBuys = () => {
    const TabData = Object.keys(tabImages); // Extract tab names
    const [activeTab, setActiveTab] = useState(TabData[0]); // Default to first tab

    const ActiveTab =
        "bg-[#FFEAEF] border-b-[#E5002A] rounded-t-lg text-[#404040] font-semibold";
    const NormalTab =
        "w-full grid place-items-center whitespace-nowrap text-[#737373] font-medium text-sm md:text-sm lg:text-base border border-b-2 border-transparent hover:border-b-[#E5002A] py-3 px-10 ease-in-out duration-700 cursor-pointer";

    return (
        <div className="bg-white rounded-xl p-4 mt-5 mb-6 relative z-50 container mx-auto max-w-6xl">
            {/* Tabs */}
            <div className="w-full border-b border-[#E5E5E5] grid grid-flow-col place-items-start overflow-x-scroll scrollBarHidden">
                {TabData.map((tab, index) => (
                    <div
                        key={index}
                        className={`${NormalTab} ${activeTab === tab ? ActiveTab : ""}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </div>
                ))}
            </div>

            {/* Tab Content */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-[#726E75] mt-4">
                {tabImages[activeTab].map((item, index) => (
                    <div className="flex flex-col gap-4" key={index}>
                        <p className="underline cursor-pointer"> {item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FooterTabbingBuys;
