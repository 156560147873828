import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout1 from "../../Layouts/Layout1";
import axiosInstance from "../../apiInstances/axiosInstance";

const SkeletonLoader = () => (
    <div className="max-w-5xl mx-auto px-5 py-10 animate-pulse">
        <div className="w-full h-64 bg-gray-300 rounded-lg mb-6"></div>
        <div className="h-8 bg-gray-300 w-3/4 rounded-md mb-4"></div>
        <div className="h-4 bg-gray-300 w-1/4 rounded-md mb-4"></div>
        <div className="h-32 bg-gray-300 rounded-lg"></div>
    </div>
);

const BlogDetail = () => {
    const { id } = useParams();
    const [blogsDetail, setBlogsDetail] = useState(null);
    const [loading, setLoading] = useState(true);

    const getBlogsDetails = async () => {
        try {
            const res = await axiosInstance.get(`/blog/${id}`);
            setBlogsDetail(res.data?.data);
        } catch (error) {
            console.log("err --->", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBlogsDetails();
    }, [id]);

    if (loading) {
        return (
            <Layout1>
                <SkeletonLoader />
            </Layout1>
        );
    }

    if (!blogsDetail) {
        return (
            <Layout1>
                <div className="text-center text-red-500 text-xl my-40 bg-white">Blog not found!</div>
            </Layout1>
        );
    }

    return (
        <Layout1>
            <div className="max-w-5xl mx-auto px-5 py-10">
                <img src={blogsDetail.coverImage} alt={blogsDetail.title} className="w-full h-64 object-cover rounded-lg mb-6" />
                <h1 className="text-3xl font-bold mb-4">{blogsDetail.title}</h1>
                <p className="text-sm text-gray-500  mb-4">{new Date(blogsDetail.updatedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })}</p>
                <div className="text-gray-500 leading-relaxed rounded-lg">
                    <div dangerouslySetInnerHTML={{ __html: blogsDetail?.content }} />
                </div>
            </div>
        </Layout1>
    );
};

export default BlogDetail;
