const getTimeAgo = (date) => {

  const now = new Date();
  const parsedDate = new Date(date);  // Parse the date string to a Date object.
  const diff = Math.floor((now - parsedDate) / 1000); // Difference in seconds

  if (diff < 60) return `${diff} seconds ago`;
  if (diff < 3600) return `${Math.floor(diff / 60)} minutes ago`;
  if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;
  return `${Math.floor(diff / 86400)} days ago`;
};


const DateFormat = (date) => {
  const dateModify = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  return dateModify
}

export { getTimeAgo, DateFormat }