import React, { useState } from 'react'
import Buyning from '../../../assets/home/buying.svg'
import Buyning2 from '../../../assets/home/buying2.svg'
import Buyning3 from '../../../assets/home/buying3.svg'
import Renting from '../../../assets/home/renting.svg'
import Renting2 from '../../../assets/home/renting2.svg'
import Renting3 from '../../../assets/home/renting3.svg'
import Selling from '../../../assets/home/selling.svg'
import Selling2 from '../../../assets/home/selling2.svg'
import Selling3 from '../../../assets/home/selling3.svg'
import Researching from '../../../assets/home/researching.svg'
import Researching2 from '../../../assets/home/researching2.svg'
import Researching3 from '../../../assets/home/researching3.svg'



const categories = [
    {
        type: "Buying",
        items: [
            {
                title: "Get estimated property prices with a realEstimate™",
                description: "See how much your property's worth whether you own it or want to buy it.",
                link: "Check property values",
                image: Buyning,
            },
            {
                title: "Need help with a mortgage?",
                description: "Compare your finance options to make an informed call.",
                link: "Explore home loans",
                image: Buyning2,
            },
            {
                title: "Explore suburb profiles",
                description: "Check out different suburb profiles and find one that’s right for you.",
                link: "Research suburbs",
                image: Buyning3,
            },
        ],
    },
    {
        type: "Renting",
        items: [
            {
                title: "Set up your renter profile",
                description: "Are you a tenant looking for a new place? Get yourself ready to apply online.",
                link: "Create a profile",
                image: Renting,
            },
            {
                title: "Find a tenant",
                description: "List your property for rent through an agent or on your own.",
                link: "Advertise your rental property",
                image: Renting2,
            },
            {
                title: "Looking for a Flatmate?",
                description: "Move into a new sharehouse, or find someone to join yours.",
                link: "Head to flatmates.com.au",
                image: Renting3,
            },
        ],
    },
    {
        type: "Selling",
        items: [
            {
                title: "Check this week’s sold prices",
                description: "Stay in the loop with the latest clearance rates and recent sales.",
                link: "See auctions result",
                image: Selling,
            },
            {
                title: "Decide whether to sell or stay",
                description: "Wondering if now’s a good time to sell? See your options.",
                link: "Read the selling guides",
                image: Selling2,
            },
            {
                title: "Thinking about refinancing?",
                description: "Calculate your equity to weigh up your financial options.",
                link: "Use the refinance calculator",
                image: Selling3,
            },
        ],
    },
    {
        type: "Researching",
        items: [
            {
                title: "Delve in market data",
                description: "Stay on top of real estate trends written by economists and property experts.",
                link: "Research the property market",
                image: Researching,
            },
            {
                title: "Real estate how-to’s",
                description: "Read up on the ins and outs of any property process.",
                link: "Explore Property guides",
                image: Researching2,
            },
            {
                title: "Get your finance in order",
                description: "Use our calculators to understand your financial position and options.",
                link: "Browse home loan calculator",
                image: Researching3,
            },
        ],
    },
];

const TabbingProperty = () => {
    const [selectedCategory, setSelectedCategory] = useState("Buying");

    return (
        <div className="p-4 mt-[10px]">
            <h2 className='font-medium text-2xl mb-4'>Explore all things property</h2>
            {/* Category Buttons */}
            <div className="flex gap-3 mb-6 overflow-auto" style={{ scrollbarWidth: "none" }}>
                {categories.map((category) => (
                    <button
                        key={category.type}
                        className={`px-3 md:px-4 py-2 border-2 rounded-full text-base font-medium ${selectedCategory === category.type
                            ? "border-black text-black"
                            : "border-gray-300 text-gray-500 hover:border-gray-500"
                            } transition`}
                        onClick={() => setSelectedCategory(category.type)}
                    >
                        {category.type}
                    </button>
                ))}
            </div>

            {/* Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {categories
                    .find((category) => category.type === selectedCategory)
                    ?.items.map((item, index) => (
                        <div key={index} className="bg-white p-4 rounded-lg border border-[#E5E5E5]">
                            <div className='flex md:flex-col gap-4'> 
                                <img src={item.image} alt={item.title} className="w-[84px] h-[84px] md:w-32 md:h-32  rounded-md lg:mb-4 lg:mx-auto" />
                                <div className=''>
                                    <h3 className="text-base md:text-lg font-semibold">{item.title}</h3>
                                    <p className="text-gray-600 my-2">{item.description}</p>
                                    <a href="#" className="text-[#00639E] hover:underline font-medium">
                                        {item.link}
                                    </a>
                                </div>

                            </div>

                        </div>
                    ))}
            </div>
        </div>
    );
};

export default TabbingProperty