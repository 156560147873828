import React, { useState } from "react";
import info from "../../../assets/infoOutline.png";
import verify from "../../../assets/verify.png";
import MoreBlue from "../../../assets/MoreBlue.png";
import a2 from "../../../assets/a2.png";
import agencyAgentImg7 from "../../../assets/agencyAgentImg (7).png";
import Review from "../Review/Review";
import { FaStar } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import ReadMoreText from "../ReadMoreText";

const ReviewsCard = ({ totalaverage, reviewlength }) => {
  const [isActive, setisActive] = useState("Sellers");

  const ActiveTab =
  " border-b-[#E5002A] rounded-t-lg text-[#3D3B40] font-light text-base";
const NormalTab =
  "w-1/2  grid place-items-center  whitespace-nowrap text-[#737373] font-light text-sm md:text-sm lg:text-base border border-b-2 border-transparent hover:border-b-[#E5002A] py-3 px-10 ease-in-out duration-700 cursor-pointer";

  const ReviewTags = [
    "Professional (328)",
    "Great communicator (271)",
    "Genuine (268)",
    "Trustworthy (223)",
    "Reliable (209)",
    "Got a great price (180)",
    "Suburb specialist (171)",
    "Great negotiator (135)",
    "Punctual (123)",
    "Great marketer (118)",
  ];

  const SellersReviews = [
    {
      profile: agencyAgentImg7,
      name: "Savannah Davis",
      post: "Seller of House in Upper Coomera, Qld",
      time: "2 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
    {
      profile: a2,
      name: "Brad Wilson",
      post: "Seller of House in Upper Coomera, Qld",
      time: "3 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
    {
      profile: agencyAgentImg7,
      name: "Savannah Davis",
      post: "Seller of House in Upper Coomera, Qld",
      time: "5 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
  ];

  const BuyersReviews = [
    {
      profile: a2,
      name: "Brad Wilson",
      post: "Buyer of House in Upper Coomera, Qld",
      time: "1 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
    {
      profile: agencyAgentImg7,
      name: "Savannah Davis",
      post: "Buyer of House in Upper Coomera, Qld",
      time: "2 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
    {
      profile: a2,
      name: "Brad Wilson",
      post: "Buyer of House in Upper Coomera, Qld",
      time: "3 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
    {
      profile: agencyAgentImg7,
      name: "Savannah Davis",
      post: "Buyer of House in Upper Coomera, Qld",
      time: "5 days ago",
      rating: "5.0",
      review:
        "From our first meeting with Brad we knew we were in great company. His professionalism and knowledge of the market left us feeling confident and initial appraisal met our expectations. To further credit Brad he has the support of a well orchestrated team of positive and encouraging people with exceptional communication and correspondence throughout the whole experience. We are both thankful for the successful sale of our property and are excited for the new...",
    },
  ];

  return (
    <div className="flex flex-col justify-center items-start bg-[#FFFFFF] md:rounded-xl  p-4 md:p-6" style={{ boxShadow: "0px 1px 4px 0px #00000029" }}> 
      <div className="w-full flex justify-between sm:justify-start items-center gap-3">
        <div className="text-[#3D3B40] text-xl font-light ">
          Our ratings and reviews
        </div>
        <img src={info} alt="icon" className="w-4 cursor-pointer" />
      </div>
      <div className="text-[#3D3B40] font-light text-sm md:text-base ">
        Read the latest reviews for the team at Ray White Coomera - COOMERA
      </div>

      {/* ------ Ratings ------ */}
      {/* <div className="flex justify-center items-center mt-3">
        <Review rating={totalaverage} />
      </div> */}
      <div className="flex gap-1 items-center mt-3">
        <FaStar className="text-[#FFB200] text-base" />
        <p className="text-base font-light text-[#333F48] pb-0 ml-1"> {totalaverage ? totalaverage : '0'} ({reviewlength} reviews) </p>
      </div>
      {/* ------ Tags ------ */}

      <div className="text-base font-light text-[#333F48] mt-[18px]">
        Clients say the Ray White Coomera - COOMERA team are...
      </div>

      <div className="flex flex-wrap justify-start items-center gap-2 my-5">
        {ReviewTags?.length > 0 &&
          ReviewTags?.map((i, index) => (
            <div
              key={index}
              className="flex justify-center items-center bg-[#E5E3E8] rounded text-sm font-light text-[#3D3B40] p-2"
            >
              {i}
            </div>
          ))}
      </div>

      {/* ------ Tabs ------ */}

      <div
        id="navigation"
        className="w-full grid place-items-center overflow-x-scroll md:overflow-hidden rounded-xl"
      >
        <div className="w-full bg-[#FFFFFF] rounded-xl py-4">
          <div className="flex justify-between items-center border border-b-2 border-transparent border-b-[#E5E5E5]">
            <div
              className={`${NormalTab} ${isActive === "Sellers" ? ActiveTab : ""
                }`}
              onClick={(e) => {
                setisActive("Sellers");
              }}
            >
              Sellers
            </div>
            <div
              className={`${NormalTab} ${isActive === "Buyers" ? ActiveTab : ""
                }`}
              onClick={(e) => {
                setisActive("Buyers");
              }}
            >
              Buyers
            </div>
          </div>
        </div>
      </div>

      {isActive === "Sellers" && (
        <div className="w-full grid grid-cols-1 gap-4">
          {SellersReviews?.length > 0 &&
            SellersReviews?.map((i, index) => (
              <>
                <div className="bg-[#F6F5F7] p-4 mt-4 rounded-lg" key={index+1}>
                  <div className="flex  justify-between items-center">
                    <div className="flex gap-1">
                      <FaStar className="text-[#FFB200] text-sm" />
                      <FaStar className="text-[#FFB200] text-sm" />
                      <FaStar className="text-[#FFB200] text-sm" />
                      <FaStar className="text-[#FFB200] text-sm" />
                      <FaStar className="text-[#FFB200] text-sm" />
                      <p className="text-base font-light text-[#333F48] pb-0 ml-2">{i?.rating}</p>
                    </div>
                    <h3 className="text-xs font-normal text-[#333F48] flex"> <MdVerified size={16} />  Verified review</h3>
                  </div>

                  <p className="text-sm font-light text-[#3D3B40]">{i?.post}</p>
                  <p className="text-xs font-light text-[#726E75]">{i?.time}</p>

                  <div className="max-w-3xl mt-3">
                    <ReadMoreText text={i?.review} color='#1E5091' />
                  </div>

                  <div className="mt-3">
                    <div className="flex gap-3 items-center">
                      <img src={i?.profile} alt="icon" className="w-6 h-6" />
                      <p className=" text-sm font-light text-[#3D3B40]">{i?.name}</p>
                    </div>
                  </div>
                </div>

              </>
            ))}
          <div className="flex justify-center">
            <button
              className="border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] mt-4 mb-[10px]"
            >
              Show more properties
            </button>
          </div>
        </div>
      )}

      {isActive === "Buyers" && (
        <div className="w-full grid grid-cols-1 gap-4">
          {BuyersReviews?.length > 0 &&
            BuyersReviews?.map((i, index) => (
              <div className="bg-[#F6F5F7] p-4 mt-4 rounded-lg" key={index}>
                <div className="flex  justify-between items-center">
                  <div className="flex gap-1">
                    <FaStar className="text-[#FFB200] text-sm" />
                    <FaStar className="text-[#FFB200] text-sm" />
                    <FaStar className="text-[#FFB200] text-sm" />
                    <FaStar className="text-[#FFB200] text-sm" />
                    <FaStar className="text-[#FFB200] text-sm" />
                    <p className="text-base font-light text-[#333F48] pb-0 ml-2">{i?.rating}</p>
                  </div>
                  <h3 className="text-xs font-normal text-[#333F48] flex"> <MdVerified size={16} />  Verified review</h3>
                </div>

                <p className="text-sm font-light text-[#3D3B40]">{i?.post}</p>
                <p className="text-xs font-light text-[#726E75]">{i?.time}</p>

                <div className="max-w-3xl mt-3">
                  <ReadMoreText text={i?.review} color='#1E5091' />
                </div>

                <div className="mt-3">
                  <div className="flex gap-3 items-center">
                    <img src={i?.profile} alt="icon" className="w-6 h-6" />
                    <p className=" text-sm font-light text-[#3D3B40]">{i?.name}</p>
                  </div>
                </div>
              </div>
            ))}
          <div className="flex justify-center">
            <button
              className="border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] mt-4 mb-[10px]"
            >
              Show more properties
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsCard;
