import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import showcaseAgency from "../../../assets/showcase-agency.png";
import Image0 from "../../../assets/image (0).png";
import bedroom_icon from "../../../assets/bedroom_icon.png";
import shower_icon from "../../../assets/shower_icon.png";
import car_icon from "../../../assets/car_icon.png";
import square from "../../../assets/square.png";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight, MdOutlineMailOutline } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";


const PropertyShowcaseCard = ({ PropertyData }) => {
  const navigate = useNavigate();
  const randomIndex = Math.floor(Math.random() * PropertyData.length)
  return (
    <>
      {PropertyData?.length > 0 && (
        <>
          <div className=" hidden lg:grid ">
            <div className="text-start font-medium text-base   text-[#737373] px-8 pb-10">
              <button
                className="join w-full flex justify-center items-center gap-2 py-3 px-5 rounded-lg border bg-[#E5002A] text-white hover:bg-white  hover:text-[#E5002A] hover:border-[#E5002A] font-medium"
              // onClick={() => {
              //   navigate("/sign-up");
              // }}
              >
                <MdOutlineMailOutline size={20} /> Request a free appraisal
              </button>

              <div className="flex items-center mt-3 gap-3">
                <div className="flex justify-center items-center gap-2 border-2 border-[#E5E5E5] rounded-lg py-3 w-full ">
                  <MdOutlineMailOutline size={18} />
                  <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base">
                    Enquire
                  </div>

                </div>
                <div className="flex justify-center items-center gap-2 border-2 border-[#E5E5E5] rounded-lg py-3 w-full ">
                  <IoCallOutline size={18} />
                  <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base">
                    Call agency
                  </div>
                </div>
              </div>


            </div>
            <div className="max-w-lg grid place-items-center bg-white rounded-2xl shadow-md hover:shadow-lg overflow-hidden">
              <div className="bg-[#172C66] w-full h-10 flex justify-center items-center">
                <img src={PropertyData[randomIndex]?.agencyLogo} alt="agency_logo" className="h-6 object-cover" />
              </div>

              <div className="">
                <div className="flex flex-col justify-center gap-1">
                  {PropertyData?.length === 1 ? (
                    <div className="data1 w-full ">
                      <LazyLoadImage
                        src={PropertyData[0]?.frontPageImg[0]}
                        alt="icon"
                        srcSet={Image0}
                        loading="lazy"
                        effect="blur"
                        className="w-full h-56"
                      />
                    </div>
                  ) : (
                    <div className="relative mainImg ">
                      <LazyLoadImage
                        src={PropertyData[randomIndex]?.frontPageImg[0]}
                        alt="icon"
                        srcSet={Image0}
                        loading="lazy"
                        effect="blur"
                        className="w-full h-56"
                      />
                      <div className="text-white text-sm font-medium absolute bottom-[18px] left-4 ">
                        {`${PropertyData[randomIndex]?.street_address_name}`}
                      </div>
                    </div>
                  )}
                  {PropertyData?.length > 1 && (
                    <div className="grid grid-cols-2 gap-1 subimg -mt-1">
                      {PropertyData?.slice(0, 2).map((data, key) => (
                        <LazyLoadImage
                          key={key}
                          src={data?.frontPageImg[0]}
                          alt="icon"
                          srcSet={data?.frontPageImg[0]}
                          loading="lazy"
                          effect="blur"
                          className="h-28"
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="mx-2 p-2">
                  <div className="text-[#3D3B40] text-sm font-medium mt-4">
                    {`${PropertyData[randomIndex]?.street_address_name}`}
                  </div>

                  <div className="flex flex-wrap justify-start items-center gap-3 mt-2">
                    <div className="flex justify-center items-center rounded-md ">
                      <img
                        src={bedroom_icon}
                        alt="location"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-[#3D3B40] text-xs lg:text-base">
                        {`${PropertyData[randomIndex]?.Bedrooms}`}
                      </div>
                    </div>
                    <div className="flex justify-center items-center rounded-md ">
                      <img
                        src={shower_icon}
                        alt="bedroom"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-[#3D3B40] text-xs lg:text-base">
                        {`${PropertyData[randomIndex]?.Bathrooms}`}
                      </div>
                    </div>
                    <div className="flex justify-center items-center rounded-md ">
                      <img
                        src={car_icon}
                        alt="shower"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-[#3D3B40] text-xs lg:text-base">
                        {`${PropertyData[randomIndex]?.carport_spaces}`}
                      </div>
                    </div>

                    <div className="flex justify-center items-center rounded-md text-[#3D3B40]">
                      <img src={square} alt="car" className="w-4 mr-1" />
                      <div className="font-normal text-[#3D3B40] text-xs lg:text-base">
                        {`${PropertyData[randomIndex]?.squareCount}`}
                        <span className="me-1 font-bold text-base">·</span>
                        {PropertyData[randomIndex]?.property_type}

                      </div>
                    </div>
                  </div>

                  <div className="mt-3 cursor-pointer w-32 text-center">
                    <div
                      className="py-2 px-3 text-[#3D3B40] font-normal text-sm border border-[#959199] rounded-lg "
                      onClick={() =>
                        navigate(`/property-house/${PropertyData[randomIndex]?.id}`)
                      }
                    >
                      View Property
                    </div>
                  </div>

                </div>
                <div className="relative mt-3 bg-[#001F49]">

                  <div className="font-normal p-[10px] text-white text-sm lg:text-lg mt-3 text-center">
                    {`${PropertyData[randomIndex]?.lead_agent?.name}`}
                  </div>

                  <div className="absolute -bottom-1 z-50 right-0">
                    <LazyLoadImage
                      src={PropertyData[randomIndex]?.lead_agent?.profileImg}
                      alt="icon"
                      srcSet={PropertyData[randomIndex]?.lead_agent?.profileImg}
                      loading="lazy"
                      effect="blur"
                      className="w-20 h-20 rounded-full aspect-square mr-2 "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center pl-[11px] pr-[16px] py-4 bg-white rounded-2xl cursor-pointer mt-3 mb-5">
              <div>
                <h3 className="text-2xl font-light text-[#000000] mb-1">What's your property worth?</h3>
                <p className="text-sm text-[#000000] font-light">Get a guide to the market.</p>
              </div>
              <MdKeyboardArrowRight className="text-[#3D3B40]" size={18} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyShowcaseCard;