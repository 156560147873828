import React, { useState } from "react";
import mailWhite from "../../../assets/mailWhite.png";
import upArrowRed from "../../../assets/upArrowRed.png";
import sideArrowRed from "../../../assets/sideArrowRed.png";
import realEstate from "../../../assets/realEstate.mp4";
import { useNavigate } from "react-router-dom";
import isEmpty from "../utils/isEmpty";
import ReadMoreText from "../ReadMoreText";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const AgentAboutCard = ({ AgentData }) => {
  let respone = isEmpty(AgentData);
  const navigate = useNavigate();
  const [showMoreSpecialties, setShowMoreSpecialties] = useState(false);
  const [showMoreAwards, setShowMoreAwards] = useState(false);


  let dummyText = "Brad is an award-winning real estate professional who truly strives to exceed expectations for every homeowner. His attention to detail, structured processes, constant communication, and Brad is an award-winning real estate professional who truly strives to exceed expectations for every homeowner. His attention to detail, structured processes, constant communication"

  const specialties = [
    "Customer focused approach",
    "Highly trained in Auction and Private Treaty campaign execution",
    "Skilled negotiator, ensuring the highest possible price available in the market is achieved. Average sale price exceeding Upper Coomera's industry average",
  ];

  const extraSpecialties = [
    "Extensive knowledge of local property markets",
    "Strong marketing strategies to attract potential buyers",
    "Expert in property investment consulting",
    "Dedicated to seamless client experiences",
  ];

  const AWARDS = [
    "Chairman's Elite Performer (Top 1% within the Ray White Network)",
    "QLD #1 selling principal 23/24",
    "Ray White Queensland's #1 Auction performer 23/24",
  ];



  return (
    <>
      {!respone && (

        <div className="bg-white p-4 md:rounded-xl" style={{ boxShadow: "0px 1px 4px 0px #00000029" }}>
          <h3 className="text-xl font-normal text-[#3D3B40]"> About {AgentData?.first_name}</h3>

          <div className="mt-[10px] mb-[30px]">

            <ReadMoreText line={2} text={dummyText} /> {/*   {AgentData?.about_me} */}

            <div className="mt-4">
              <h3 className="text-[#3D3B40] text-sm font-light">SPECIALITIES</h3>
              <ul className="list-disc list-inside text-[#3D3B40] text-base font-light mt-1 px-2">
                {specialties.map((item, index) => (
                  <li key={index} className="mb-1">- {item}</li>
                ))}
                {showMoreSpecialties &&
                  extraSpecialties.map((item, index) => (
                    <li key={index + specialties.length} className="mb-1">- {item}</li>
                  ))}
              </ul>

              <button
                className={`flex items-center gap-1 text-base  font-medium text-[#00639E] hover:underline ml-1`}
                onClick={() => setShowMoreSpecialties(!showMoreSpecialties)}
              >
                Read more   <MdOutlineArrowBackIosNew size={16} className={` ${showMoreSpecialties ? ' rotate-90' : "-rotate-90"}`} />
              </button>
            </div>

            <div className="w-full mt-4 cursor-pointer">
              <iframe
                width="100%"
                height="340px"
                src={`https://www.youtube.com/embed/${AgentData?.video_URL.slice(
                  32,
                  43
                )}`}
              ></iframe>
              {/* <video width="full" height="full" controls>
                    <source src={realEstate} type="video/mp4" />
                  </video> */}
            </div>


            <div>
              <div className="mt-4">
                <h3 className="text-[#3D3B40] text-sm font-light">AWARDS</h3>
                <ul className="list-disc list-inside text-[#3D3B40] text-base font-light mt-1 px-2">
                  {AWARDS.map((item, index) => (
                    <li key={index} className="mb-1">- {item}</li>
                  ))}
                  {showMoreAwards &&
                    extraSpecialties.map((item, index) => (
                      <li key={index + specialties.length} className="mb-1">- {item}</li>
                    ))}
                </ul>
                <button
                  className={`flex items-center gap-1 text-base font-medium text-[#00639E] hover:underline ml-1`}
                  onClick={() => setShowMoreAwards(!showMoreAwards)}
                >
                  Read more  <MdOutlineArrowBackIosNew size={16} className={` ${showMoreAwards ? ' rotate-90' : "-rotate-90"}`} />
                </button>
              </div>
            </div>

            <button
              className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg bg-[#E4002B] py-3 px-2 cursor-pointer text-white text-center mt-3"
              onClick={() => {
                navigate(`/appraisal/${AgentData?._id}`);
              }}
            >
              <img
                src={mailWhite}
                alt="icon"
                className=""
              />
              <span>Request a free appraisal</span>
            </button>

          </div>
        </div>
      )}





      {/* {respone === false && (
        <div className="flex flex-col justify-start items-start bg-[#FFFFFF] rounded-xl shadow-md hover:shadow-lg p-4 md:p-6">
          <div className="text-[#171717] font-bold text-base md:text-lg lg:text-xl">
            About {AgentData?.first_name}
          </div> */}
      {/* <div className="text-[#737373] font-medium text-sm md:text-sm mt-2">
            5 years of experience
          </div> */}

      {/* <div className="text-[#525252] font-medium text-xs md:text-sm mt-4 md:mt-6">
            {AgentData?.about_me}
          </div>
          <div className="flex justify-start items-center gap-2 mt-4">
            <div className="text-[#E5002A] font-semibold text-xs md:text-sm cursor-pointer">
              Read less
            </div>
            <img src={upArrowRed} alt="icon" className="w-3 cursor-pointer" />
          </div>
          <div className="text-[#171717] font-semibold text-sm md:text-base mt-4 md:mt-6">
            SPECIALITIES
          </div>
          <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
            {AgentData?.specialties}
          </div> */}
      {/* <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
        - Customer focused approach (most recommended agent in Upper Coomera)
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        - Highly trained in Auction and Private Treaty campaign execution with
        proven results
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        - Average sale price exceeding industry average for Upper Coomera
      </div> */}
      {/* <div className="flex justify-start items-center gap-2 mt-4">
            <div className="text-[#E5002A] font-semibold text-xs md:text-sm cursor-pointer">
              Read more
            </div>
            <img src={sideArrowRed} alt="icon" className="w-4 cursor-pointer" />
          </div>

          <div className="w-full  mt-4 md:mt-6 rounded-lg cursor-pointer">
            <iframe
              width="100%"
              height="400px"
              src={`https://www.youtube.com/embed/${AgentData?.video_URL.slice(
                32,
                43
              )}`}
            ></iframe> */}
      {/* <video width="full" height="full" controls>
          <source src={realEstate} type="video/mp4" />
        </video> */}
      {/* </div>

          <div className="text-[#171717] font-semibold text-sm md:text-base mt-4 md:mt-6">
            AWARDS
          </div>
          <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
            {AgentData?.awards}
          </div> */}
      {/* <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
        Awarded Ray White Elite performer 20-21, Elite performer 21-22 (top 3%
        ray white agents internationally)
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        Awarded Premier member sales status 2021 - 2022, Awarded Premier member
        sales status 2020 - 2021.
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        Awarded Agent of the year for Upper Coomera (Rate my Agent) 2021
      </div> */}
      {/* <div className="flex justify-start items-center gap-2 mt-4">
            <div className="text-[#E5002A] font-semibold text-xs md:text-sm cursor-pointer">
              Read more
            </div>
            <img src={sideArrowRed} alt="icon" className="w-4 cursor-pointer" />
          </div>
          <div className="text-[#171717] font-semibold text-sm md:text-base mt-4 md:mt-6">
            COMMUNITY INVOLVEMENT
          </div>
          <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
            {AgentData?.community_involvement}
          </div> */}
      {/* <div className="text-[#525252] font-medium text-sm md:text-sm mt-2">
        As an individual agent, Brad supports Ray White Coomera/Upper Coomera
        and the fundraising efforts for our local community.
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        Currently we are proud supporters of
      </div>
      <div className="text-[#525252] font-medium text-sm md:text-sm">
        - Upper Coomera State College and their Future Stars Program.
      </div> */}
      {/* <div className="flex justify-start items-center gap-2 mt-4">
            <div className="text-[#E5002A] font-semibold text-xs md:text-sm cursor-pointer">
              Read more
            </div>
            <img src={sideArrowRed} alt="icon" className="w-4 cursor-pointer" />
          </div>

          <div
            className="w-full grid place-items-center mt-5 md:mt-8"
            onClick={() => {
              navigate(`/appraisal/${AgentData?._id}`);
            }}
          >
            <button className="w-full flex justify-center items-center gap-2 text-xs font-medium border text-[#FFFFFF] bg-[#E5002A] border-[#E5002A] py-3 px-5 rounded-3xl">
              <img src={mailWhite} alt="icon" className="w-4 cursor-pointer" />
              <div>Request Appraisal</div>
            </button>
          </div>
        </div> */}
      {/* )} */}
    </>
  );
};
export default AgentAboutCard;
