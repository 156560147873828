
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollRestoration = () => {
  const location = useLocation();

  useEffect(() => {

    const savedScroll = sessionStorage.getItem(`scroll-${location.key}`);
    setTimeout(() => {
      if (savedScroll !== null) {
        window.scrollTo(0, parseInt(savedScroll, 10));
      } else {
        window.scrollTo(0, 0);
      }
    }, 100);

    const handleScroll = () => {
      sessionStorage.setItem(`scroll-${location.key}`, window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

};

export default useScrollRestoration;

