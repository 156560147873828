import React, { useDeferredValue, useEffect, useState } from "react";
import Layout2 from "../../Layouts/Layout2";
import FindAgencyCard from "../common/FindAgencyCard/FindAgencyCard";
import FindAgentCard from "../common/FindAgentCard/FindAgentCard";
import search from "../../assets/search.png";
import ag1 from "../../assets/ag1.png";
import rs1 from "../../assets/rs1.png";
import rs2 from "../../assets/rs2.png";
import rs3 from "../../assets/rs3.png";
import rs4 from "../../assets/rs4.png";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loder from "../common/Loder";
import HeaderImg from "../../assets/findAgent/headerImg.webp"
import { IoCloseOutline, IoFilter, IoInformationCircleOutline } from "react-icons/io5";
import { FaEllipsisH } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";



const FindAgent = () => {
  const [IsActive, setIsActive] = useState("Agents");
  const [AgentData, setAgentData] = useState([]);

  const [Filtered, setFiltered] = useState([]);
  const [FilteredAgency, setFilteredAgency] = useState([]);
  const deferredFilter = useDeferredValue(Filtered);
  const [Sort, setSort] = useState("");

  const [AgenciesData, setAgenciesData] = useState([]);
  const [Input, setInput] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const filterData = (searchTerm) => {
    const filteredArray = AgentData?.filter((obj) => {
      const allValue = `${obj.AgentName.toLowerCase()} ${obj.id.toLowerCase()} ${obj.Rating?.toString().toLowerCase()}`;
      searchTerm = searchTerm.toLowerCase();
      // Check if the name or ID contains all characters from the search term
      const searchCharacters = searchTerm.split("");
      return searchCharacters.every((char) => allValue.includes(char));
    });
    setFiltered(filteredArray);
  };
  const filterDataAgency = (searchTerm) => {
    const filteredArray = AgenciesData?.filter((obj) => {
      const allValue = `${obj.name?.toLowerCase()}`;
      searchTerm = searchTerm.toLowerCase();
      // Check if the name or ID contains all characters from the search term
      const searchCharacters = searchTerm.split("");
      return searchCharacters.every((char) => allValue.includes(char));
    });
    setFilteredAgency(filteredArray);
  };
  const handleSearchInput = (e) => {
    e.preventDefault();
    // Filter the data when the search term changes
    // const allValuealue = e?.target?.value;
    filterData(e.target.value);
    filterDataAgency(e.target.value);
    setInput(e.target.value);
  };
  const ActiveTab = "text-white bg-[#333F48]";
  useEffect(() => {
    GetAllAgentData();
    GetAllAgenciesData();
  }, []);
  const { key } = useParams();
  //console.log("🚀 ~ FindAgent ~ key:", key)

  const GetAllAgentData = async () => {
    setIsLoading(true);
    const response = await axiosInstanceAuth.post(
      `Agency_Agent/viewAllAgents/${key}`
    );
    if (response.data.status) {
      // console.log("------------------------------------------------------>", response.data)
      const AllSold = response.data.totalSoldCount.length;
      //console.log("🚀 ~ GetAllAgentData ~ AllSold:", AllSold)
      const agentData = response?.data?.data;
      // console.log("agentDAta", agentData);
      const newArray = agentData?.map((obj, i) => ({
        id: obj?.id,
        AgentName: obj?.name,
        AgentImage: obj?.profileImg,
        AgencySmallLogo: obj?.agencySmallLogo,
        TopHeadColor: obj?.primary_color,
        TopHeadImage: ag1,
        AgentAbout: obj?.agencyName,
        AgencyId: obj.agencyId,
        AgentExperince: "10 years experience",
        Rating: obj?.average,
        Review: obj?.reviews,
        PropertiesSoldAsLeadAgent: obj?.property_sold,
        MedianDaysPrice: obj?.medianPrice?.toFixed(2),
        MediandaysAdvertised: "16",
        PropertiesSold: AllSold,
        VerifiedReview:
          "Right from the start of our chance meeting Jason exuded comfort, in a short period of time Jason gave us more information than anyone else. Throughout the time that followed Jason and his team, specific callout to Brady, made it their passion and goal to find our forever home. With many laughs and resilience they came through and gave us the best experience we could ask for. Highly recommend Jason and will definitely seek him out again in the future! Thanks Jason and Brady...",
        RecentlySold: obj?.propertyDetails,
        // RecentlySold: [
        //   {
        //     Recently: "RECENTLY SOLD",
        //     Img: rs1,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs2,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs3,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs4,
        //   },
        // ],
      }));
      setIsLoading(false);
      setAgentData(newArray);
      setFiltered(newArray);
    } else {
      toast.error(response.data.message);
      setIsLoading(false);
    }
  };

  const GetAllAgenciesData = async () => {
    setIsLoading(true);
    await axiosInstanceAuth
      .get("agentadmin/viewAllAgency")
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          const newArray = mydata.map((obj, i) => ({
            ...obj,
            id: mydata?.[i]?.id,
            TopHeadColor: obj?.primary_color,
            agencyLargeLogo: mydata?.[i]?.agencyLargeLogo,
            name: mydata?.[i]?.name,
            street: mydata?.[i]?.street,
            suburb_area: mydata?.[i]?.suburb_area,
            postcode: mydata?.[i]?.postcode,
            Property_Sold: `${i + 978}`,
            Property_Rent: `${i + 41}`,
          }));
          setIsLoading(false);
          setAgenciesData(newArray);
        } else {
          toast.error(res?.data?.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log("err --->", err);
        setIsLoading(false);
      });
  };
  const SortAgent = async (data) => {
    const response = await axiosInstanceAuth.post(`sortAgent`, {
      sort_by: data,
    });
    if (response.data.status) {
      const agentData = response?.data?.data;
      const newArray = agentData?.map((obj, i) => ({
        id: obj?.id,
        AgentName: obj?.name,
        AgentImage: obj?.profileImg,
        AgencySmallLogo: obj?.agencySmallLogo,
        TopHeadColor: obj?.primary_color,
        TopHeadImage: ag1,
        AgentAbout: obj?.agencyName,
        AgentExperince: "10 years experience",
        Rating: obj?.average,
        Review: obj?.reviews,
        PropertiesSoldAsLeadAgent: "127",
        MedianDaysPrice: "$ 716k",
        MediandaysAdvertised: "16",
        PropertiesSold: "113",
        VerifiedReview:
          "Right from the start of our chance meeting Jason exuded comfort, in a short period of time Jason gave us more information than anyone else. Throughout the time that followed Jason and his team, specific callout to Brady, made it their passion and goal to find our forever home. With many laughs and resilience they came through and gave us the best experience we could ask for. Highly recommend Jason and will definitely seek him out again in the future! Thanks Jason and Brady...",
        RecentlySold: obj?.propertyDetails,
        // RecentlySold: [
        //   {
        //     Recently: "RECENTLY SOLD",
        //     Img: rs1,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs2,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs3,
        //   },
        //   {
        //     Recently: "",
        //     Img: rs4,
        //   },
        // ],
      }));
      setAgentData(newArray);
      setFiltered(newArray);
    } else {
      toast.error(response.data.message);
    }
  };

  const handleSelect = (e) => {
    setSort(e.target.value);
    SortAgent(e.target.value);
  };

  const [selectedType, setSelectedType] = useState("All");
  const [selectedTimeframe, setSelectedTimeframe] = useState("12 months");


  const propertyTypes = ["All", "House", "Unit & Apartment"];

  return (
    <Layout2>
      {isLoading ? (
        <div className="w-full h-[55vh] grid place-content-center">
          <Loder />
        </div>
      ) : (
        <>
          {/* ---------- Start Tab & Search & Sort---------- */}

          <div className="flex flex-col justify-start bg-white md:mb-10 max-w-6xl md:mx-3 xl:mx-auto shadow-lg md:shadow-none pb-4 md:pb-0 px-2 md:px-0">
            <h1 className="text-lg md:text-2xl font-light text-[#333F48] mb-2 md:mb-0 mt-20 lg:mt-8 xl:mt-8 ">Real estate agents in Gold Coast, QLD</h1>
            <div className="flex justify-start items-end lg:items-end gap-6">
              <div className="flex gap-4 sm:gap-10 md:gap-6 w-full">
                <div className="flex justify-start items-center border border-[#E5E5E5] rounded-[28px]  cursor-pointer h-[50px] sm:h-[54px] md:h-auto">
                  <button
                    className={`text-xs md:text-sm lg:text-base py-4 md:py-3 px-6 md:px-7 rounded-[28px] text-[#404040] ${IsActive === "Agents" ? ActiveTab : ""
                      }`}
                    onClick={() => {
                      setIsActive("Agents");
                    }}
                  >
                    Agents
                  </button>
                  <button
                    className={`text-xs md:text-sm lg:text-base py-4 md:py-3 px-6 md:px-7 rounded-[28px] text-[#404040] ${IsActive === "Agencies" ? ActiveTab : ""
                      }`}
                    onClick={() => {
                      setIsActive("Agencies");
                    }}
                  >
                    Agencies
                  </button>
                </div>
                {/* filter mobile screen */}
                <div className="border border-[#697684] text-[#333F48] text-base font-medium rounded-[3px] py-[14px] px-2 flex justify-center items-center w-[115px] gap-2 md:hidden ">
                  <IoFilter /> <h4 className="italic">Filter</h4>
                </div>
                <div className="relative hidden md:flex justify-start items-center border border-[#E5E5E5] rounded py-3 px-4  cursor-pointer min-w-[172px] xl:w-full ">
                  <img
                    src={search}
                    alt="icon"
                    className="w-3 lg:w-4 mr-3 cursor-pointer"
                  />
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    value={Input}
                    className="w-full text-[#737373] font-medium text-xs md:text-sm lg:text-base outline-none"
                    onChange={(e) => handleSearchInput(e)}
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 " onClick={() => { alert("undefined") }}>
                    <IoCloseOutline className=" text-[#333F48] pointer-events-none text-base" />
                  </div>
                </div>
              </div>
              <div className="hidden md:flex flex-col justify-start ">
                <div className="text-[#333F48] font-light text-xs md:text-sm lg:text-base mb-1 px-2">
                  Sort By
                </div>
                <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base ">
                  <select
                    name="filter"
                    value={Sort}
                    onChange={(e) => {
                      handleSelect(e);
                    }}
                    className="round outline-none border border-[#E5E5E5] rounded py-3 px-[14px] cursor-pointer lg:w-[408px]"
                  >
                    <option value="number_of_properties_sold">
                      Number of properties sold
                    </option>
                    <option value="suburb_sales_and_performance">
                      Suburb Sales & Performance
                    </option>
                    <option value="property_sales_as_leadagent">
                      Property sales (as lead agent)
                    </option>
                    <option value="number_of_reviews">
                      Number of reviews
                    </option>
                    <option value="median_sold_price">
                      Median sold price
                    </option>
                    {/* <option value="median_days_advertised">
                        Median days advertised
                      </option> */}
                    <option value="years_experience">Years experience</option>
                    <option value="total_sales_across_all_suburbs">
                      Total sales across all suburbs
                    </option>
                  </select>
                </div>
              </div>
            </div>


            <div>
              <div className="hidden md:flex flex-wrap items-center gap-4 mt-6 ">
                {/* Property Sales Type */}
                <div className="gap-2 ">
                  <h3 className="text-[#333F48] text-base pb-3">Property sales type</h3>
                  <div className="flex items-center gap-2">
                    {propertyTypes.map((type) => (
                      <button
                        key={type}
                        className={`px-4 py-3 text-base font-light border rounded-md transition-all ${selectedType === type
                          ? "bg-gray-800 text-white"
                          : "border-gray-300 text-gray-700 hover:bg-gray-100"
                          }`}
                        onClick={() => setSelectedType(type)}
                      >
                        {type}
                      </button>
                    ))}

                    <button className="px-4 py-4 border rounded-md border-gray-300 text-[#333F48] text-base font-light hover:bg-gray-100">
                      <FaEllipsisH className="text-[#333F48]" />
                    </button>
                  </div>
                </div>

                {/* Timeframe Dropdown */}
                <div className="gap-2">
                  <h3 className="text-[#333F48] font-light text-xs md:text-sm lg:text-base px-2  pb-3">Timeframe</h3>
                  <div className="text-[#737373] font-medium text-xs md:text-sm lg:text-base w-full">
                    <select
                      className="round outline-none border border-[#E5E5E5] rounded py-3 px-[14px] cursor-pointer w-[365px]"
                      value={selectedTimeframe}
                      onChange={(e) => setSelectedTimeframe(e.target.value)}
                    >
                      <option>6 months</option>
                      <option>12 months</option>
                      <option>24 months</option>
                    </select>
                    {/* <MdKeyboardArrowDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none" /> */}
                  </div>
                </div>
              </div>
            </div>



            {/* <div className="flex flex-row justify-start mt-5 lg:mt-7 px-2">
              <input type="checkbox" className="mr-3 cursor-pointer" />
              <div className="font-medium text-[#737373] text-xs md:text-sm">
                Offices located in this area
              </div>
            </div> */}
          </div>

          {/* ---------- End Tab & Search & Sort ---------- */}

          <div className="px-5 bg-[#F7F8F9]">
            <div className="mt-0 md:mt-16 lg:mt-0 max-w-6xl mx-auto">
              <div className="pt-4 mb-3">
                <h3 className="text-base text-[#333F48] font-light mb-3">Showing {deferredFilter?.length} results</h3>
                <h5 className="text-sm font-normal text-[#333F48] flex items-center gap-3 ">Suburb Sales & Performance <IoInformationCircleOutline size={16} /></h5>
              </div>
              {IsActive === "Agents" ? (
                <FindAgentCard AgentData={deferredFilter} style="mb-10" />
              ) : null}
              {IsActive === "Agencies" ? (
                <FindAgencyCard
                  AgenciesData={Input ? FilteredAgency : AgenciesData}
                  style="mb-10"
                />
              ) : null}

            </div>
          </div>
        </>
      )}
    </Layout2>
  );
};

export default FindAgent;
