import React, { useRef, useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import UserImg from "../../assets/home/user.jpg";
import Mortgage from "../../assets/home/mortgage.svg";

const brokersData = [
    {
        id: 1,
        name: "Bevan O'Farrell",
        role: "Mortgage broker",
        location: "Based in Perth",
        image: "",
    },
    {
        id: 2,
        name: "Peter Keenan",
        role: "Mortgage broker",
        location: "Based in Perth",
        image: "",
    },
    {
        id: 3,
        name: "Jessica Li",
        role: "Mortgage broker",
        location: "Based in Perth",
        image: "",
    },
    {
        id: 4,
        name: "Gavin Rushack",
        role: "Mortgage broker",
        location: "Based in Subiaco",
        image: "",
    },
    {
        id: 4,
        name: "Gavin Rushack",
        role: "Mortgage broker",
        location: "Based in Subiaco",
        image: "",
    },
    {
        id: 4,
        name: "Gavin Rushack",
        role: "Mortgage broker",
        location: "Based in Subiaco",
        image: "",
    },
    {
        id: 4,
        name: "Gavin Rushack",
        role: "Mortgage broker",
        location: "Based in Subiaco",
        image: "",
    },
];

const LocalAgents = () => {
    const scrollRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

            // Show left arrow if scrolled right
            setShowLeftArrow(scrollLeft > 0);
            // Show right arrow if not fully scrolled to the end
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        };

        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            handleScroll(); // Initial check
        }

        return () => scrollContainer?.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    return (
        <div className="relative p-6 mb-12">
            <h2 className="text-2xl font-semibold mb-6">Local Agents</h2>
            <div className="flex gap-6 items-center">
                {/* Left Scroll Button */}
                {showLeftArrow && (
                    <button onClick={scrollLeft} className="absolute left-0 z-10 bg-white shadow-md p-2 rounded-full">
                        <FaChevronLeft size={24} />
                    </button>
                )}

                {/* Scrollable Brokers List */}
                <div className="relative flex-1 overflow-hidden">
                    <div ref={scrollRef} className="flex gap-4 overflow-x-scroll hidden-scrollbar scroll-smooth" style={{ scrollbarWidth: "none" }}>
                        <div key={''} className="min-w-[250px] m-1 bg-white shadow-md rounded-lg" style={{boxShadow: '0px 1px 4px 0px #00000029'}}>
                            <img src={Mortgage} alt={"broker.name"} className=" mx-4 rounded-full object-cover mt-[50px]" />
                            <div className="p-3">
                                <p className="text-gray-600 text-sm mb-[42px]">{"Enter a postcode to find mortgage brokers near you."}</p>
                               
                            </div>
                            <div className="p-4 mt-3">
                                <label htmlFor="postcode" className="text-sm font-normal ">Postcode</label>
                                <input type="text" className="w-full p-2 rounded-lg border border-gray-400 text-sm" placeholder="Enter a postcode" />
                                <button className="w-full mt-3 rounded-lg py-2 text-sm bg-[#3D3B40] text-white">Find the broker</button>
                                </div>
                        </div>
                        {brokersData.map((broker) => (
                            <div key={broker.id} className="min-w-[250px] m-1 bg-white rounded-lg" style={{boxShadow: '0px 1px 4px 0px #00000029'}}>
                                <div className="bg-teal-700 text-white p-3 rounded-t-lg font-semibold">mortgage Choice</div>
                                <img src={broker.image ? broker.image : UserImg} alt={broker.name} className="w-20 h-20 mx-4 mt-4 rounded-full object-cover" />
                                <div className="p-4">
                                    <h3 className="text-md font-semibold">{broker.name}</h3>
                                    <p className="text-gray-600 text-sm">{broker.role}</p>
                                    <p className="text-gray-500 text-sm mt-3 mb-[34px]">{broker.location}</p>
                                    <a href="#" className="text-[#726E75] mt-2 inline-block underline">See more details</a>
                                    <button className="w-full mt-3 border rounded-lg py-2 text-sm">Request a call back</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Right Scroll Button */}
                {showRightArrow && (
                    <button onClick={scrollRight} className="absolute right-0 z-10 bg-white shadow-md p-2 rounded-full">
                        <FaChevronRight size={24} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default LocalAgents;
