import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import info from "../../../assets/infoOutline.png";
import location from "../../../assets/location_icon.png";
import bedroom from "../../../assets/bedroom_icon.png";
import car from "../../../assets/car_icon.png";
import shower from "../../../assets/shower_icon.png";
import Googlemap2 from "../GoogleMap/Googlemap2";
import isEmpty from "../utils/isEmpty";

const PropertiesCard = ({ AgencyData, property }) => {
  const resPonce = isEmpty(property);
  const navigate = useNavigate();
  const [visibleProperties, setVisibleProperties] = useState(3);
  const [showMore, setShowMore] = useState(true);
  const toggleShowMore = () => {
    setShowMore(!showMore);
    setVisibleProperties(showMore ? property.length : 3);
  };


  return (
    <>

      {AgencyData.length > 0 &&
        AgencyData?.map((data, i) => (
          <div className="bg-white md:rounded-xl shadow-[0px_1px_4px_0px_rgba(0,0,0,0.2)]" key={i}>
            <div>
              <div className="p-4 pb-1 md:pb-4">
                <div className="w-full flex justify-between sm:justify-start items-center gap-3 ">
                  <div className="text-[#3D3B40] font-normal text-xl">
                    Our properties
                  </div>
                  <img src={info} alt="icon" className="w-4 cursor-pointer" />
                </div>
                {/* once verify this description content */}
                <div className="text-[#3D3B40] font-normal text-sm ">
                  {data?.principal_name} have sold 1014 properties of all time on realestate.com.ng, have 60
                  properties for sale and have 14 properties for rent.
                </div>
                {/* --------- Sort By --------- */}
                <div className="w-full flex flex-col md:flex-row justify-start gap-4 mt-4 mb-1 md:mb-5">
                  <div className="">
                    <select
                      name="select"
                      className="round w-full font-light !text-[#3D3B40] text-base outline-none border border-[#3D3B40] rounded-lg md:rounded-[28px] py-2 px-6 cursor-pointer"
                    // onChange={(e) => changeSort(e.target.value)}
                    >
                      <option value="Active">Buy</option>
                      <option value="Rent">Rent</option>
                      <option selected value="Sold">Sold</option>
                    </select>
                  </div>
                  <div className="hidden md:block">
                    <select
                      name="select"
                      className="round w-full font-light !text-[#3D3B40] text-xs md:text-base outline-none border border-[#3D3B40] rounded-[28px] py-2 px-6 cursor-pointer"
                      onChange={(e) => { '' }}
                    >
                      <option value="1month">1 months</option>
                      <option value="6month">6 months</option>
                      <option selected value="12month">12 months</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* -------- Map -------- */}
              <div className="!relative h-[342px] rounded-md">
                {<Googlemap2 location={property} />}
              </div>

              {/* -------- property Cards -------- */}
              <div className="p-[10px]">
                <div className="text-[#3D3B40] font-light text-sm">
                  Showing {data?.properties && data?.properties?.length > 1 ? data?.properties?.length > 3 ? 3 : data?.properties?.length : 0} of {data?.properties?.length} properties sold on realestate.com.ng in the last 12 months
                </div>
              </div>
            </div>

            <div key={i} className="bg-[#FFFFFF] rounded-xl shadow-md p-3">
              <div className="mt-1 flex flex-col gap-4">
                {data?.properties?.length > 0 &&
                  data?.properties.slice(0, visibleProperties).map((i, index) => (
                    <div
                      key={index}
                      className=" relative  flex flex-col md:flex-row md:gap-4 cursor-pointer border border-[#E5E3E8] rounded-xl"
                      onClick={() => navigate(`/property-house/${i?._id || i?.id}`)}
                    >
                      <LazyLoadImage
                        src={i?.frontPageImg[0]}
                        alt="icon"
                        srcSet={i?.iconTop}
                        loading="lazy"
                        effect="blur"
                        className="rounded-[12px_12px_0px_0px] md:rounded-[12px_0px_0px_12px] h-full w-full md:max-w-[250px] object-cover"
                      />

                      <div className="absolute max-[767px]:top-4 md:bottom-2 left-2 bg-white p-1 rounded-lg flex items-center gap-1">
                        <div className="w-2 h-2 p-1 rounded-full bg-[#FCCA36]"></div>
                        <span className="text-xs font-normal">Sold {property.soldDate}</span>
                      </div>

                      <div className="px-[22px] md:px-0 my-5">
                        <div className="flex justify-start items-center">
                          <div className="font-normal text-xl text-[#3D3B40]">
                            {i?.Hide_the_price_and_display_contact_agent
                              ? i?.price
                              : "Contact agent"}
                          </div>
                        </div>

                        <div className="flex justify-start items-start gap-2 mt-2 md:mt-3">
                          <div className="font-normal  text-[#3D3B40] text-sm">
                            {i?.street_address_number} {i?.street_address_name}
                          </div>
                        </div>

                        <div className="flex flex-wrap justify-start items-center gap-3 my-[10px] md:my-4">
                          <div className="flex justify-center items-center rounded-md">
                            <img
                              src={bedroom}
                              alt="location"
                              className="w-4 mr-1"
                            />
                            <div className="font-normal text-base text-[#3D3B40]">
                              {i?.Bedrooms}
                            </div>
                          </div>

                          <div className="flex justify-center items-center rounded-md">
                            <img
                              src={shower}
                              alt="bedroom"
                              className="w-4 mr-1"
                            />
                            <div className="font-normal text-base text-[#3D3B40]">
                              {i?.Bathrooms}
                            </div>
                          </div>
                          <div className="flex justify-center items-center rounded-md">
                            <img src={car} alt="shower" className="w-4 mr-1" />
                            <div className="font-normal text-base text-[#3D3B40]">
                              {i?.carport_spaces}
                            </div>
                          </div>
                          <div className=" border-r border-[#E5E3E8] my-2 h-4"></div>
                          <div className="flex justify-center items-center rounded-md">
                            <div className="font-normal text-sm text-[#3D3B40]" title={i?.property_type}>
                              {i?.property_type.substring(0, 10)}
                            </div>
                          </div>
                        </div>
                        <div className="text-start font-normal text-[#3D3B40] text-sm mb-2">
                          Sold on{" "}
                          {new Date(i?.createdAt).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })}
                        </div>
                      </div>

                      <div className="absolute right-7 bottom-4">
                        {/* once verify this images need to replace icon URL */}
                        <LazyLoadImage
                          src={i?.frontPageImg[0]}
                          alt="icon"
                          srcSet={i?.iconTop}
                          loading="lazy"
                          effect="blur"
                          className="rounded-full h-6 w-6 object-cover "
                        />
                      </div>

                    </div>
                  ))}
              </div>

              {/* -------- button -------- */}
              {property.length > 3 && (
                <div className="flex justify-center">
                  <button
                    className="border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] mt-4 mb-[10px]"
                    onClick={toggleShowMore}
                  >
                    {showMore ? "Show less reviews" : `Show more properties`}
                  </button>
                </div>
              )}

            </div>

          </div>))}
    </>
  );
};

export default PropertiesCard;
