import React from "react";

const TalkToBroker = () => {
  return (
    <div className="w-full bg-white rounded-2xl p-6 flex flex-col space-y-4">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img src="/logo.png" alt="Mortgage Choice" className="h-6" /> Mortgage Choice

        </div>
        <div className="hidden md:block">
          <img src="https://picsum.photos/200/300" alt="Broker" className="w-12 h-12 rounded-full" />

        </div>
      </div>

      {/* Title and Description */}
      <div className="flex justify-between" >
        <div className="">
          <h2 className="text-xl font-medium text-[#3D3B40]">Talk to a Mortgage Choice broker</h2>
          <p className="text-[#3D3B40] text-sm mt-3 w-full max-w-[368px]">
            While you search for your new home, we'll search for your home loan.
          </p>
        </div>

        <div className="text-end flex-col justify-end hidden md:flex">
          <p className="text-[#3D3B40] text-sm mt-3">
            Tyla
          </p>
          <p className="text-[#3D3B40] text-sm ">
            Home Loans Concierge, Sydney
          </p>
        </div>
      </div>

      {/* Input Fields */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        <input
          type="text"
          placeholder="Name"
          className="flex-1 p-3 border rounded-xl text-[#726E75] font-medium text-xs md:text-sm lg:text-base outline-none"
        />
        <input
          type="text"
          placeholder="Phone Number"
          className="flex-1 p-3 border rounded-xl text-[#726E75] font-medium text-xs md:text-sm lg:text-base outline-none"
        />
        {/* Submit Button */}
        <button className="w-full bg-[#F6F5F7] text-[#BEBAC2] p-3 rounded-xl cursor-not-allowed hidden md:block">
          Speak with Tyla
        </button>
      </div>

      {/* Checkbox */}
      <div className="flex items-center space-x-3 text-sm text-[#3D3B40]">
        <input type="checkbox" className="w-4 h-4 border-[#959199] rounded" />
        <label>I'm in Western Australia. Check the local time before you call.</label>
      </div>
      <button className="w-full bg-[#F6F5F7] text-[#BEBAC2] p-3 rounded-xl cursor-not-allowed md:hidden">
          Speak with Tyla
        </button>


      <div className=" md:hidden flex items-center gap-6">
        <img src="https://picsum.photos/200/300" alt="Broker" className="w-12 h-12 rounded-full" />

        <div className="">
          <p className="text-[#3D3B40] text-sm mt-3">
            Tyla
          </p>
          <p className="text-[#3D3B40] text-sm ">
            Home Loans Concierge, Sydney
          </p>
        </div>
      </div>
      {/* Privacy Statement */}
      <p className="text-sm text-gray-500 underline cursor-pointer pt-[2px]">Privacy collection statement</p>
    </div>
  );
};

export default TalkToBroker;
