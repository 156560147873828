import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import PlaceHolderImage from '../../assets/home/placeholder-image.webp';
import PowerBy from '../../assets/home/powerBy.png';
import { usePropertyData } from '../../context/PropertyDataContext';
import { DateFormat } from '../common/utils/getTimeAgo';
import { Link } from 'react-router-dom';

const LeastNewsProperty = () => {

    const { fetchBlogs, blogCache } = usePropertyData();

    useEffect(() => {
        fetchBlogs();
    }, []);

    const newsData = blogCache?.map((data) => {
        return {
            id: data?._id,
            title: data?.title,
            image: data?.coverImage,
            readTime: data.createdAt,
            sponsor: data.sponsor
        }
    })

    const scrollRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        };

        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            handleScroll(); // Run initially to set correct button states
        }

        return () => scrollContainer?.removeEventListener("scroll", handleScroll);
    }, []);

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = 300;
            scrollRef.current.scrollBy({ left: direction === "left" ? -scrollAmount : scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <div className="relative p-6">
            <h2 className="text-2xl font-semibold mb-4">Latest property news</h2>
            <div className="relative">
                <div ref={scrollRef} className="flex gap-4 overflow-x-scroll scroll-smooth" style={{ scrollbarWidth: "none" }} >
                    {newsData.map((news) => (
                        <Link to={`/blogs/${news.id}`}>
                            <div key={news.id} className="min-w-[250px] md:min-w-[280px] bg-white  rounded-lg">
                                <img src={news.image ? news.image : PlaceHolderImage} alt={news.title} className="w-full h-32 object-cover rounded-t-lg" />
                                <div className="py-3">
                                    <h3 className="text-md font-semibold">{news.title}</h3>
                                    <p className="text-gray-500 text-sm mt-2 flex items-center gap-2">
                                        {DateFormat(news.readTime)} {news.sponsor && <img src={PowerBy} className='w-16 h-8' alt='' />}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                {showLeftArrow && (
                    <button onClick={() => scroll("left")} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white shadow-md p-2 rounded-full">
                        <FaChevronLeft size={24} />
                    </button>
                )}
                {/* Right Scroll Button (Initially visible, hides at end) */}
                <button onClick={() => scroll("right")} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white shadow-md p-2 rounded-full text-[#3D3B40]">
                    <FaChevronRight size={24} />
                </button>
            </div>
        </div>
    )
}

export default LeastNewsProperty