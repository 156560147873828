import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { FaHome, FaStar } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import email from "../../../assets/message_icon.png";
import mailWhite from "../../../assets/mailWhite.png";
import PaginationNormal from "../Pagination/PaginationNormal";

const FindAgencyCard = (props) => {
  const navigate = useNavigate();
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  // Assuming you have an array of data called `myData`
  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = startIndex + PageSize;
  const currentData = props.AgenciesData.slice(startIndex, endIndex);

  const ReviewTags = [
    "Professional (328)",
    "Genuine (268)",
    "Great communicator (135)",

  ];
  return (
    <>
      <div
      // className={`
      //   // grid place-items-center grid-cols-1 xl:grid-cols-2  gap-8 ${props.style} `
      // }
      >
        {currentData?.length > 0 &&
          currentData?.map((i, index) => (
            <div key={index} className="w-full bg-gray-50 pb-8">
              {/* Main content */}
              <div className="max-w-7xl mx-auto" onClick={() => navigate(`/agency-profile/${i?.id}`)}>
                <Card className="shadow-md overflow-hidden rounded">
                  <CardContent className="p-0">
                    <div className="mb-3">
                      {/* Header with yellow banner */}
                      <div className={`w-full px-6 py-2 flex items-center ${i?.TopHeadColor
                        ? i?.TopHeadColor.includes('#') ? `bg-[${i.TopHeadColor}]` : `bg-${i.TopHeadColor}-700`
                        : `bg-[#FFE512]`}`}>
                        <LazyLoadImage
                          src={
                            i?.agencyLargeLogo && i.agencyLargeLogo.includes('uploads')
                              ? `https://backend.myrealestate-ng.com/${i.agencyLargeLogo}`
                              : i?.agencyLargeLogo
                          }
                          alt="icon"
                          srcSet={
                            i?.agencyLargeLogo && i.agencyLargeLogo.includes('uploads')
                              ? `https://backend.myrealestate-ng.com/${i.agencyLargeLogo}`
                              : i?.agencyLargeLogo
                          }
                          loading="lazy"
                          effect="blur"
                          className="h-5"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row justify-center items-start p-3">
                        {/* Agency info */}
                        <div className="w-full md:w-6/12 px-3 md:px-0">
                          <div>
                            <h2 className="text-lg md:text-2xl font-semibold text-gray-800">{i?.name}</h2>
                            <p className="text-gray-600 mt-1 text-sm">{i?.street} {i?.suburb_area} {i?.postcode}</p>

                            {/* Rating */}
                            <div className="flex items-center gap-2 mt-2">
                              <div className="flex justify-center items-center gap-1">
                                <FaStar size={16} className="text-[#FFB200]" />
                                {/* <img src={star} alt="star" className="w-4" /> */}
                                <span className="font-medium">5.0</span>
                              </div>
                              <span className="text-gray-600">(690 reviews)</span>
                            </div>
                            <p className="text-sm text-gray-500 mt-1">184 reviews in last 12 months</p>
                            <div className="flex flex-wrap justify-start items-center gap-2 my-5">
                              {ReviewTags?.length > 0 &&
                                ReviewTags?.map((i, index) => (
                                  <div
                                    key={index}
                                    className="flex justify-center items-center bg-[#E5E3E8] rounded text-xs font-light text-[#3D3B40] p-1.5"
                                  >
                                    {i}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        {/* Performance metrics */}
                        <div className="w-full md:w-9/12 flex flex-col lg:flex-row md:justify-center md:items-center divide-y-2 lg:divide-y-0 lg:divide-x-2 gap-3 px-3 md:px-0">
                          {/* Sales performance */}
                          <div className="mt-4 md:mt-0">
                            <h3 className="text-base font-medium text-gray-800 mb-4">Sales performance</h3>
                            <div className="flex justify-between gap-4 items-center">
                              <div>
                                <div className="flex justify-start mb-2">
                                  <FaHome />
                                </div>
                                <p className="text-xl font-bold text-gray-900">{i?.Property_Sold}</p>
                                <p className="text-xs text-gray-600 mt-2">
                                  Properties sold in Adelaide - Greater Region
                                </p>
                              </div>
                              <div>
                                <div className="flex justify-start mb-2">
                                  <GoClock />
                                </div>
                                <p className="text-xl font-bold text-gray-900 ">0</p>
                                <p className="text-xs text-gray-600  mt-2">Median days advertised</p>
                              </div>
                            </div>
                          </div>

                          {/* Rent performance */}
                          <div className="pl-3 pt-2 lg:pt-0">
                            <h3 className="text-base font-medium text-gray-800 mb-4">Rent performance</h3>
                            <div className="flex justify-between gap-4 items-center">
                              <div>
                                <div className="flex justify-start mb-2">
                                  <FaHome />
                                </div>
                                <p className="text-xl font-bold text-gray-900 ">{i?.Property_Rent}</p>
                                <p className="text-xs text-gray-600  mt-2">
                                  Properties leased in Adelaide - Greater Region
                                </p>
                              </div>
                              <div>
                                <div className="flex justify-start mb-2">
                                  <GoClock />
                                </div>
                                <p className="text-xl font-bold text-gray-900 ">0</p>
                                <p className="text-xs text-gray-600  mt-2">Median days advertised</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* CTA Buttons */}
                    <div className="pt-4 flex  justify-end items-center">
                      <div className="flex flex-col md:flex-row justify-end items-center pr-4 gap-3 w-full lg:w-[60%] px-6">
                        <button
                          className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg border border-[#959199] text-[#3D3B40] py-3 px-2 cursor-pointer text- text-center"
                          onClick={() => { }}
                        >
                          <img src={email} alt="icon" className="w-4 cursor-pointer" />
                          <span>Enquire</span>
                        </button>
                        <button
                          className="w-full flex justify-center items-center gap-2 text-base font-normal rounded-lg bg-[#E4002B] py-3 px-2 cursor-pointer text-white text-center"
                          onClick={() => {
                            // navigate(`/appraisal/${i?._id}`);
                          }}
                        >
                          <img
                            src={mailWhite}
                            alt="icon"
                            className=""
                          />
                          <span>Request a free appraisal</span>
                        </button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          ))}
      </div>

      {/* <<----- Paginationp ----->> */}
      <div className="flex justify-center items-center pb-4">
        <PaginationNormal
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={props?.AgenciesData?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default FindAgencyCard;
