import React, { useEffect, useRef, useState } from "react";
import UserImg from "../../assets/home/user.jpg";
import PlaceHolderImage from '../../assets/home/placeholder-image.webp';
import bedroom from "../../assets/bedroom_icon.png";
import car from "../../assets/car_icon.png";
import shower from "../../assets/shower_icon.png";
import DummyImage from "../../assets/buy/dummy.jpg"
import DummyImage2 from "../../assets/buy/dummy2.jpg"
import DummyImage3 from "../../assets/buy/dummy3.jpg"
import { FaChevronRight } from "react-icons/fa";


const properties = [
    {
        image: DummyImage,
        agencyName: "COASTAL",
        agencyColor: "bg-green-500",
        agentPhoto: "",
        agentName: "John Doe",
        soldDate: "24 Jan 2025",
        price: 761500,
        address: "45/2320-2330 Gold Coast Highway, Mermaid Beach, Qld",
        bedrooms: 2,
        bathrooms: 5,
        carSpaces: 5,
    },
    {
        image: DummyImage2,
        agencyName: "JOHN REID",
        agencyColor: "bg-gray-700",
        agentPhoto: "",
        agentName: "Jane Smith",
        soldDate: "24 Jan 2025",
        price: 3630000,
        address: "47 Beverley Crescent, Broadbeach Waters, Qld 4218",
        bedrooms: 2,
        bathrooms: 5,
        carSpaces: 5,
    },
    {
        image: DummyImage3,
        agencyName: "RayWhite",
        agencyColor: "bg-yellow-500",
        agentPhoto: "",
        agentName: "Michael Brown",
        soldDate: "24 Jan 2025",
        price: 950000,
        address: "21/9-13 Margaret Avenue, Broadbeach, Qld 4218",
        bedrooms: 2,
        bathrooms: 5,
        carSpaces: 5,
    },
];


const RecentlySoldProperties = () => {


    const scrollRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

            // Show left arrow if scrolled right
            setShowLeftArrow(scrollLeft > 0);
            // Show right arrow if not fully scrolled to the end
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        };

        const scrollContainer = scrollRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            handleScroll(); // Initial check
        }

        return () => scrollContainer?.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    return (
        <div className="py-3 max-w-3xl pr-4 mb-7 relative">
            <h2 className="text-2xl font-bold mb-4">Recently sold properties</h2>
            <div ref={scrollRef} className="flex gap-6 overflow-x-scroll hidden-scrollbar scroll-smooth" style={{ scrollbarWidth: "none" }}>
                {properties.map((property, index) => (
                    <div key={index} className="rounded-xl  overflow-hidden min-w-[200px] ">
                        <div
                            className={`relative w-full flex items-center justify-between p-3 h-7 ${property.agencyColor}`}
                        >
                            <span className="text-white font-bold px-2 py-1">{property.agencyName}</span>
                            <div className=" absolute right-2 z-20 top-2">
                                <img
                                    src={property.agentPhoto ? property.agentPhoto : "https://rd92vy8mqtsruuha.public.blob.vercel-storage.com/download%20(1)-XrIAPTFAvocNnKuDBc7SvJnXyFAOB8.jpeg"}
                                    alt={property.agentName}
                                    className="w-10 h-10 rounded-full border-2 object-cover"
                                />
                            </div>
                        </div>
                        <div className="relative">
                            <img
                                src={property.image ? property.image : PlaceHolderImage}
                                alt={property.agencyName}
                                className="w-full h-[140px] rounded-b-lg"
                            />

                            <div className="absolute bottom-2 left-2 bg-white p-1 rounded-lg flex items-center gap-1">
                                <div className="w-2 h-2 p-1 rounded-full bg-[#FCCA36]"></div>
                                <span className="text-xs font-normal">Sold {property.soldDate}</span>
                            </div>


                        </div>
                        <div className="">
                            <h3 className="text-xl font-bold mb-4 mt-[10px]">${property.price.toLocaleString()}</h3>
                            <p className="text-gray-600 text-sm">{property.address}</p>
                            <div className="flex justify-start items-center gap-3 mt-4">
                                <div className="flex items-center text-sm">
                                    <img src={bedroom} alt="bedroom" className="w-4 mr-1" />
                                    {property.bedrooms}
                                </div>
                                <div className="flex items-center text-sm">
                                    <img src={shower} alt="shower" className="w-4 mr-1" />
                                    {property.bathrooms}
                                </div>
                                <div className="flex items-center text-sm">
                                    <img src={car} alt="car" className="w-4 mr-1" />
                                    {property.carSpaces}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {showRightArrow && (<div className="absolute right-0 top-[50%] z-10">
                    <button onClick={scrollRight} className=" bg-white shadow-md p-2 rounded-full ">
                        <FaChevronRight size={24} />
                    </button>
                </div>

                )}
            </div>


        </div>
    );
};

export default RecentlySoldProperties;