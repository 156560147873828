import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../apiInstances/axiosInstance";

// Initial state
const initialState = {
  listings: [],
  isLoading: false,
  error: null,
};

// Async thunk for fetching listing data
export const fetchListings = createAsyncThunk(
  "listings/fetchListings",
  async (filter = {}, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("Agency/viewAllProperty", {
        status: filter.status,
      });

      if (res?.data?.status) {
        const mydata = res?.data?.data;

        const newArray = mydata.map((obj, i) => ({
          ...obj,
          id: mydata[i]?.id,
          frontPageImg: mydata[i]?.frontPageImg,
          agencyImg: "agency4", // Replace with actual image reference
          agentImg: "agent2", // Replace with actual image reference
          lead_agent: mydata[i]?.lead_agent,
          price: mydata[i]?.price,
          price_display_checked: mydata[i]?.price_display_checked,
          price_display: mydata[i]?.price_display,
          street_address_number: mydata[i]?.street_address_number,
          street_address_name: mydata[i]?.street_address_name,
          favourite: false,
          bedroomCount: mydata[i]?.Bedrooms,
          showerCount: mydata[i]?.Bathrooms,
          carCount: mydata[i]?.carport_spaces,
          squareCount: "6,580 m2",
          acreageCount: mydata[i]?.property_type,
        }));

        // Update favorite status from local storage
        let newResponse = newArray.map((x) => ({
          ...x,
          favourite: localStorage.getItem("Saved")?.split(",").includes(String(x.id)),
        }));

        return newResponse;
      } else {
        toast.error(res?.data?.message);
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

// Slice
const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.listings = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchListings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default listingsSlice.reducer;
