import { createContext, useContext, useState } from 'react';
import axiosInstance from '../apiInstances/axiosInstance';

const PropertyDataContext = createContext();

export const PropertyDataProvider = ({ children }) => {
  const [propertyCache, setPropertyCache] = useState({});
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const [blogCache, setBlogCache] = useState([]);
  const CACHE_EXPIRY_MS = 5 * 60 * 1000;

  const getCachedData = (key) => {
    if (propertyCache[key] && Date.now() - lastFetchTime < CACHE_EXPIRY_MS) {
      return propertyCache[key];
    }
    return null;
  };

  const cachePropertyData = (key, data) => {
    setPropertyCache(prev => ({
      ...prev,
      [key]: data
    }));
    setLastFetchTime(Date.now());
  };

  const fetchBlogs = async () => {
    if (blogCache.length > 0 && Date.now() - lastFetchTime < CACHE_EXPIRY_MS) {
        return;
    }

    try {
        const res = await axiosInstance.get(`/blog`);
        setBlogCache(res.data?.data || []);
        setLastFetchTime(Date.now());
    } catch (error) {
        console.error("Error fetching blogs:", error);
    }
};
  return (
    <PropertyDataContext.Provider value={{ getCachedData, cachePropertyData, fetchBlogs, blogCache }}>
      {children}
    </PropertyDataContext.Provider>
  );
};

export const usePropertyData = () => useContext(PropertyDataContext);
