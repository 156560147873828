import React, { useState } from "react";
import logoSmall from "../../assets/logo-new.png";
import { footerData } from "./FooterData";
import { Link, useLocation } from "react-router-dom";

import {
  FaFacebook,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import FooterTabbing from "../home/FooterTabbing";
import FooterTabbingBuys from "../home/FooterTabbingBuys";

export const Footer = () => {
  const sliderData = Object.keys(footerData);
  const [isActiveTab, setIsActiveTab] = useState(sliderData[0]);

  const pathName = useLocation().pathname;

  const icon = [
    {
      icons: FaFacebook,
      link: "https://www.facebook.com/myrealestate.ng.2023",
      iconsColor: "hover:text-blue-500",
    },
    // {
    //   icons: FaLinkedin,
    //   link: "https://in.linkedin.com/",
    //   iconsColor: "hover:text-[#0A66C2]",
    // },
    {
      icons: FaPinterest,
      link: "https://in.pinterest.com/myrealestateng/",
      iconsColor: "hover:text-[#E60023]",
    },
    {
      icons: FaTwitter,
      link: "https://twitter.com/myrealestateng",
      iconsColor: "hover:text-[#1d9bf0]",
    },
    {
      icons: FaYoutube,
      link: "https://www.youtube.com/",
      iconsColor: "hover:text-[#F00]",
    },
  ];
  const navi = [
    {
      id: 1,
      name: "Advertise with us",
      link: "/advertise",
    },
    {
      id: 2,
      name: "Contact us",
      link: "/contactus",
    },

    {
      id: 3,
      name: "Agent admin",
      link: "#",
    },
    {
      id: 4,
      name: "Media sales",
      link: "#",
    },
    {
      id: 5,
      name: "Legal",
      link: "#",
    },
    {
      id: 6,
      name: "Privacy",
      link: "#",
    },
    {
      id: 7,
      name: "Site map",
      link: "#",
    },
    {
      id: 8,
      name: "Careers",
      link: "#",
    },
  ];

  const ActiveTab =
    "bg-[#FFEAEF] border-b-[#E5002A] rounded-t-lg text-[#404040] font-semibold";

  const NormalTab =
    "w-full md:w-[26%]  grid place-items-center whitespace-nowrap  text-[#737373] font-medium text-sm md:text-sm  lg:text-base border border-b-2 border-transparent hover:border-b-[#E5002A] py-3 px-5 ease-in-out duration-700 cursor-pointer";

  return (
    <>
      {pathName === "/" ? <FooterTabbing /> : pathName === "/buy" ? <FooterTabbingBuys /> : ""}
      <footer className="bg-white">
        <div className="text-[#404040] py-3">
          <div className="flex flex-col lg:flex-row items-center gap-2 flex-wrap justify-between mx-8 lg:mx-auto max-w-[1100px] ">
            <div className="flex gap-3">
              {icon?.map((i, index) => (
                <a href={i?.link} key={index} target="_blank">
                  <i.icons className={`text-3xl ${i.iconsColor}`} />
                </a>
              ))}
            </div>

            <div className="flex justify-center items-center gap-4 py-[18px] flex-wrap lg:flex-nowrap">
              {navi?.map((data, index) => (
                <Link key={index} className="md:text-sm text-xs" to={data.link}>{data.name}</Link>
              ))}
              <div className="md:text-sm text-xs">
                <a href="https://agent.myrealestate-ng.com/">Agent admin</a>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#F6F5F7] px-2 lg:px-0" >
          <div className="max-w-[1100px] mx-auto py-3">
            <div className="  mb-4">
              <img
                src={logoSmall}
                alt="logo"
                className="h-7 mt-1 sm:mt-0  rounded-xl cursor-pointer bg-transparent mix-blend-multiply"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-[#3D3B40] text-sm font-medium">International sites</h3>
              <ul className="text-[#3D3B40] flex flex-wrap lg:gap-2 mb-4">
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">India</a></li>
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">United States</a></li>
                <li className=" m-1 ms-0 text-xs"> <a href="#">International properties</a></li>
              </ul>

              <h3 className="text-[#3D3B40] text-sm font-medium"> Partner sites</h3>
              <ul className="text-[#3D3B40] flex lg:gap-2 mb-4 flex-wrap">
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">news.com.au
                </a></li>
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">foxsports.com.au</a></li>
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">Mansion Global</a></li>
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">askizzy.org.au</a></li>
                <li className="border-r border-black pr-2 m-1 ms-0 text-xs"> <a href="#">makaan.com</a></li>
                <li className=" m-1 ms-0 text-xs"> <a href="#">proptiger.com</a></li>
              </ul>
              <p className="text-sm text-[#3D3B40]">Copyright © 2024 myrealestate-ng.com</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;