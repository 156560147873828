import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import info from "../../../assets/infoOutline.png";
import location from "../../../assets/location_icon.png";
import bedroom from "../../../assets/bedroom_icon.png";
import car from "../../../assets/car_icon.png";
import shower from "../../../assets/shower_icon.png";
import GoogleMap2 from "../GoogleMap/Googlemap2";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";
import isEmpty from "../utils/isEmpty";

const AgentPropertiesCard = ({ AgentData, property }) => {

  const navigate = useNavigate();
  const [showListing, setShowListing] = useState(false);

  const [sort, setsort] = useState();
  const [propertyData, setpropertyData] = useState();
  const resPonce = isEmpty(property);
  useEffect(() => {
    setpropertyData(property);
  }, [property]);

  const changeSort = (e) => {
    setsort(e);
    const data = property.filter((item) => item.status == e);
    setpropertyData(data);
  };
  const showMoreLessListings = () => {
    setShowListing((prevVal) => !prevVal);
    if (showListing) {
      //console.log("---------------------")
      // Assuming you have an element with the class name "data"
      const element = document.querySelector(".data");

      if (element) {
        // Scroll to the element
        element.scrollIntoView({
          behavior: "smooth", // Use smooth scrolling behavior
          block: "start", // Scroll to the top of the element
        });
      }
    }
  };
  return (
    <div className="flex flex-col justify-center bg-[#FFFFFF] md:rounded-xl " style={{ boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16)' }}>
      <div className="p-4 pb-1 md:pb-4">

        <div className="w-full flex justify-between sm:justify-start items-center gap-3 ">
          <div className="text-[#3D3B40] font-normal text-xl">
            {AgentData?.first_name} 's properties
          </div>
          <img src={info} alt="icon" className="w-4 cursor-pointer" />
        </div>
        {/* once verify this description content */}
        <div className="text-[#3D3B40] font-normal text-sm ">
          Ray White Alliance have sold 1014 properties of all time on realestate.com.ng, have 60
          properties for sale and have 14 properties for rent.
        </div>
        {/* --------- Sort By --------- */}
        <div className="w-full flex flex-col md:flex-row justify-start gap-4 mt-4 mb-1 md:mb-5">
          <div className="">
            <select
              name="select"
              className="round w-full font-light !text-[#3D3B40] text-base outline-none border border-[#3D3B40] rounded-lg md:rounded-[28px] py-2 px-6 cursor-pointer"
              onChange={(e) => changeSort(e.target.value)}
            >
              <option value="Active">Buy</option>
              <option value="Rent">Rent</option>
              <option selected value="Sold">Sold</option>
            </select>
          </div>
          <div className="hidden md:block">
            <select
              name="select"
              className="round w-full font-light !text-[#3D3B40] text-xs md:text-base outline-none border border-[#3D3B40] rounded-[28px] py-2 px-6 cursor-pointer"
              onChange={(e) => { '' }}
            >
              <option value="1month">1 months</option>
              <option value="6month">6 months</option>
              <option selected value="12month">12 months</option>
            </select>
          </div>
        </div>
      </div>


      {/* -------- Map -------- */}
      <div className="!relative h-[342px] rounded-md">
        {resPonce ? "" : <GoogleMap2 location={property} />}
      </div>

      {/* -------- property Cards -------- */}

      <div className="p-[10px]">
        <div className="text-[#3D3B40] font-light text-sm">
          Showing {propertyData && propertyData?.length > 1 ? propertyData?.length > 3 ? 3 : propertyData?.length : 0} of {propertyData?.length} properties sold on realestate.com.ng in the last 12 months
        </div>

        <div className="mt-4 flex flex-col gap-4">
          {propertyData?.length > 0 &&
            !showListing &&
            propertyData?.slice(0, 3).map((i, index) => (
              <div
                key={index}
                className=" relative  flex flex-col md:flex-row md:gap-4 cursor-pointer border border-[#E5E3E8] rounded-xl"
                onClick={() => navigate(`/property-house/${i?._id || i?.id}`)}
              >
                <LazyLoadImage
                  src={i?.florePlansImg[0]}
                  alt="icon"
                  srcSet={i?.florePlansImg[0]}
                  loading="lazy"
                  effect="blur"
                  className="rounded-[12px_12px_0px_0px] md:rounded-[12px_0px_0px_12px] h-full w-full md:max-w-[250px] object-cover"
                />

                <div className="absolute max-[767px]:top-4 md:bottom-2 left-2 bg-white p-1 rounded-lg flex items-center gap-1">
                  <div className="w-2 h-2 p-1 rounded-full bg-[#FCCA36]"></div>
                  <span className="text-xs font-normal">Sold {property.soldDate}</span>
                </div>

                <div className="px-[22px] md:px-0 my-5">
                  <div className="flex justify-start items-center">
                    <div className="font-normal text-xl text-[#3D3B40]">
                      {i?.Hide_the_price_and_display_contact_agent
                        ? i?.price
                        : "Contact agent"}
                    </div>
                  </div>

                  <div className="flex justify-start items-start gap-2 mt-2 md:mt-3">
                    <div className="font-normal  text-[#3D3B40] text-sm">
                      {i?.street_address_number} {i?.street_address_name}
                    </div>
                  </div>

                  <div className="flex flex-wrap justify-start items-center gap-3 my-[10px] md:my-4">
                    <div className="flex justify-center items-center rounded-md">
                      <img
                        src={bedroom}
                        alt="location"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.Bedrooms}
                      </div>
                    </div>

                    <div className="flex justify-center items-center rounded-md">
                      <img
                        src={shower}
                        alt="bedroom"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.Bathrooms}
                      </div>
                    </div>
                    <div className="flex justify-center items-center rounded-md">
                      <img src={car} alt="shower" className="w-4 mr-1" />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.carport_spaces}
                      </div>
                    </div>
                    <div className=" border-r border-[#E5E3E8] my-2 h-4"></div>
                    <div className="flex justify-center items-center rounded-md">
                      <div className="font-normal text-sm text-[#3D3B40]" title={i?.property_type}>
                        {i?.property_type.substring(0, 10)}
                      </div>
                    </div>
                  </div>
                  <div className="text-start font-normal text-[#3D3B40] text-sm mb-2">
                    Sold on{" "}
                    {new Date(i?.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                </div>

                <div className="absolute right-7 bottom-4">
                  {/* once verify this images need to replace icon URL */}
                  <LazyLoadImage
                    src={i?.florePlansImg[0]}
                    alt="icon"
                    srcSet={i?.florePlansImg[0]}
                    loading="lazy"
                    effect="blur"
                    className="rounded-full h-6 w-6 object-cover "
                  />
                </div>

              </div>
            ))}

          {propertyData?.length > 0 &&
            showListing &&
            propertyData?.map((i, index) => (
              <div
                key={index}
                className=" relative flex gap-4 cursor-pointer border border-[#E5E3E8] rounded-xl"
                onClick={() => navigate(`/property-house/${i?._id || i?.id}`)}
              >
                <LazyLoadImage
                  src={i?.florePlansImg[0]}
                  alt="icon"
                  srcSet={i?.florePlansImg[0]}
                  loading="lazy"
                  effect="blur"
                  className="rounded-xl h-full w-full max-w-[250px] object-cover"
                />
                <div className="absolute bottom-2 left-2 bg-white p-1 rounded-lg flex items-center gap-1">
                  <div className="w-2 h-2 p-1 rounded-full bg-[#FCCA36]"></div>
                  <span className="text-xs font-normal">Sold {property.soldDate}</span>
                </div>

                <div className="my-5">
                  <div className="flex justify-start items-center">
                    <div className="font-normal text-xl text-[#3D3B40]">
                      {i?.Hide_the_price_and_display_contact_agent
                        ? i?.price
                        : "Contact agent"}
                    </div>
                  </div>

                  <div className="flex justify-start items-start gap-2 mt-2 md:mt-3">
                    {/* <img src={location} alt="location" className="w-4 md:w-5" /> */}
                    <div className="font-normal  text-[#3D3B40] text-sm">
                      {i?.street_address_number} {i?.street_address_name}
                    </div>
                  </div>

                  <div className="flex flex-wrap justify-start items-center gap-3 my-4">
                    <div className="flex justify-center items-center rounded-md">
                      <img
                        src={bedroom}
                        alt="location"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.Bedrooms}
                      </div>
                    </div>

                    <div className="flex justify-center items-center rounded-md">
                      <img
                        src={shower}
                        alt="bedroom"
                        className="w-4 mr-1"
                      />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.Bathrooms}
                      </div>
                    </div>
                    <div className="flex justify-center items-center rounded-md">
                      <img src={car} alt="shower" className="w-4 mr-1" />
                      <div className="font-normal text-base text-[#3D3B40]">
                        {i?.carport_spaces}
                      </div>
                    </div>
                    <div className=" border-r border-[#E5E3E8] my-2 h-4"></div>
                    <div className="flex justify-center items-center rounded-md">
                      <div className="font-normal text-sm text-[#3D3B40]" title={i?.property_type}>
                        {i?.property_type.substring(0, 10)}
                      </div>
                    </div>
                  </div>
                  <div className="text-start font-normal text-[#3D3B40] text-sm mb-2">
                    Sold on{" "}
                    {new Date(i?.createdAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                </div>

                <div className="absolute right-7 bottom-4">
                  {/* once verify this images need to replace icon URL */}
                  <LazyLoadImage
                    src={i?.florePlansImg[0]}
                    alt="icon"
                    srcSet={i?.florePlansImg[0]}
                    loading="lazy"
                    effect="blur"
                    className="rounded-full h-6 w-6 object-cover "
                  />
                </div>

              </div>
            ))}
        </div>
        {/* -------- button -------- */}
        <div className="flex justify-center">
          <button
            className="border border-[#959199] rounded-lg text-base px-6 py-3 font-light text-[#3D3B40] mt-4 mb-[10px]"
            // onClick={() => setShowListing((prevVal) => !prevVal)}
            onClick={() => showMoreLessListings()}
          >
            {showListing ? "Show less reviews" : `Show more properties`}
          </button>
        </div>

      </div>
    </div>
  );
};

export default AgentPropertiesCard;
