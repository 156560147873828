
import React, { useState } from 'react'
import { BsInfoCircle } from "react-icons/bs";
import info from "../../../assets/infoOutline.png";
import House from "../../../assets/House.png";
import Townhouse from "../../../assets/Townhouse.png";
const ProformanceCard = () => {
  const [activeTab, setActiveTab] = useState("sold")

  const soldData = [
    {
      type: "House",
      icon: House,
      sold: 464,
      medianPrice: "$900k",
      medianDays: 20,
    },
    {
      type: "Townhouse",
      icon: Townhouse,
      sold: 70,
      medianPrice: "$688k",
      medianDays: 19,
    },
    {
      type: "Duplex/Semi-detached",
      icon: Townhouse,
      sold: 22,
      medianPrice: "$736k",
      medianDays: 21,
    },
  ]

  const leasedData = [
    {
      type: "House",
      icon: House,
      sold: 312,
      medianPrice: "$550",
      medianDays: 14,
    },
    {
      type: "Townhouse",
      icon: Townhouse,
      sold: 98,
      medianPrice: "$480",
      medianDays: 12,
    },
    {
      type: "Duplex/Semi-detached",
      icon: Townhouse,
      sold: 45,
      medianPrice: "$520",
      medianDays: 15,
    },
  ]

  const data = activeTab === "sold" ? soldData : leasedData
  const soldText = activeTab === "sold" ? "Sold" : "Leased"
  const priceText = activeTab === "sold" ? "Median price" : "Median weekly rent"

  return (
    <div className="mx-auto p-6 bg-white md:rounded-xl shadow-[0px_1px_4px_0px_rgba(0,0,0,0.2)]">
      <div className="flex items-center gap-1 mb-1 text-[#3D3B40]">
        <h2 className="text-xl font-medium">Our Market Performance</h2>
        <BsInfoCircle />
      </div>
      <p className="text-[#3D3B40] mt-1 mb-4 text-sm">
        In the last 12 months agents at Ray White Alliance have sold 597 properties with a median sold price of $860k on
        realestate.com.au.
      </p>
     
      <div className="flex mb-6">
        <div className="inline-flex rounded-full border border-gray-[#959199]">
          <button
            onClick={() => setActiveTab("sold")}
            className={`font-light px-6 py-2 rounded-full text-base transition-colors ${activeTab === "sold" ? "border-black border-[0.5px]" : "hover:border-[0.5px] hover:border-gray"
              }`}
          >
            Sold
          </button>
          <button
            onClick={() => setActiveTab("leased")}
            className={`font-light px-6 py-2 rounded-full text-base transition-colors ${activeTab === "leased" ? "border-black border-[0.5px]" : "hover:border-[0.5px] hover:border-gray"
              }`}
          >
            Leased
          </button>
        </div>
      </div>
     
      <div className="space-y-4">
        {data.map((item, index) => (
          <div key={index} className="font-light text-base text-[#3D3B40] border border-[#E5E3E8] rounded-lg overflow-hidden">
            <div className="flex flex-col md:flex-row w-full">
             
              <div className="w-full md:w-4/12 p-5 flex flex-col items-start justify-center border-b md:border-b-0 md:border-r border-gray-[#959199]">
                <img src={item.icon} alt="icon" className="md:w-20" />
                <div>{item.type}</div>
              </div>
             
              <div className="w-full md:w-8/12 p-5 bg-[#F6F5F7]">
                <div className="grid grid-cols-2 gap-y-3 md:grid-cols-2 text-base">
                 
                  <div className="font-light text-[#3D3B40]">{soldText}</div>
                  <div className="text-right font-medium">{item.sold} {soldText}</div>

                 
                  <div className="font-light text-[#3D3B40]">Median price</div>
                  <div className="text-right font-medium">{item.medianPrice}</div>

                 
                  <div className="font-light text-[#3D3B40]">Median days advertised</div>
                  <div className="text-right font-medium">{item.medianDays} days</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProformanceCard



// import React, { useState } from "react";
// import info from "../../../assets/infoOutline.png";
// import House from "../../../assets/House.png";
// import Townhouse from "../../../assets/Townhouse.png";

// const ProformanceCard = () => {
//   const [IsActive, setIsActive] = useState("Sold");

//   const ActiveTab = "text-white bg-[#E5002A]";

//   const SoldData = [
//     {
//       Image: House,
//       Type: "House",
//       Title1: "107",
//       Description1: "sold",
//       Title2: "$ 758k",
//       Description2: "Median price",
//       Title3: "21",
//       Description3: "Median days advertised",
//     },
//     {
//       Image: Townhouse,
//       Type: "Townhouse",
//       Title1: "20",
//       Description1: "sold",
//       Title2: "$ 520k",
//       Description2: "Median price",
//       Title3: "22",
//       Description3: "Median days advertised",
//     },
//     {
//       Image: Townhouse,
//       Type: "Duplex/Semi-detached",
//       Title1: "8",
//       Description1: "sold",
//       Title2: "$ 573k",
//       Description2: "Median price",
//       Title3: "23.5",
//       Description3: "Median days advertised",
//     },
//   ];

//   const LeasedData = [
//     {
//       Image: House,
//       Type: "House",
//       Title1: "170",
//       Description1: "Leased",
//       Title2: "$ 758k",
//       Description2: "Median price",
//       Title3: "21",
//       Description3: "Median days advertised",
//     },
//     {
//       Image: Townhouse,
//       Type: "Townhouse",
//       Title1: "120",
//       Description1: "Leased",
//       Title2: "$ 520k",
//       Description2: "Median price",
//       Title3: "22",
//       Description3: "Median days advertised",
//     },
//     {
//       Image: Townhouse,
//       Type: "Duplex/Semi-detached",
//       Title1: "80",
//       Description1: "Leased",
//       Title2: "$ 573k",
//       Description2: "Median price",
//       Title3: "23.5",
//       Description3: "Median days advertised",
//     },
//   ];

//   return (
//     <div className="flex flex-col justify-center items-start bg-[#FFFFFF] rounded-xl shadow-md hover:shadow-lg p-4 md:p-6">
//       <div className="w-full flex justify-between sm:justify-start items-center gap-3">
//         <div className="text-[#171717] font-bold text-base md:text-lg lg:text-xl">
//           Our Market Performance
//         </div>
//         <img src={info} alt="icon" className="w-4 cursor-pointer" />
//       </div>
//       <div className="text-[#404040] font-medium text-sm md:text-sm lg:text-base mt-2">
//         In the last 12 months agents at Ray White Coomera - COOMERA have sold
//         141 properties with a median sold price of $700k on realestate.com.au.
//       </div>

//       <div className="w-full sm:w-auto flex justify-center items-center border border-[#E5E5E5] rounded-[28px] cursor-pointer mt-4 md:mt-6">
//         <button
//           className={`w-full sm:w-auto text-xs md:text-sm lg:text-base py-3 px-10 rounded-[28px] text-[#404040] ${
//             IsActive === "Sold" ? ActiveTab : null
//           }`}
//           onClick={() => {
//             setIsActive("Sold");
//           }}
//         >
//           Sold
//         </button>
//         <button
//           className={`w-full sm:w-auto text-xs md:text-sm lg:text-base py-3 px-10 rounded-[28px] text-[#404040] ${
//             IsActive === "Leased" ? ActiveTab : null
//           }`}
//           onClick={() => {
//             setIsActive("Leased");
//           }}
//         >
//           Leased
//         </button>
//       </div>

//       <div className="w-full grid place-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-4 md:mt-6">
//         {IsActive === "Sold" &&
//           SoldData?.length > 0 &&
//           SoldData?.map((i, index) => (
//             <div
//               key={index}
//               className="flex flex-col justify-center items-center  gap-4 bg-[#F5F5F5] rounded-lg shadow-md hover:shadow-lg cursor-pointer p-4"
//             >
//               <div className="flex flex-col justify-center items-center">
//                 <img src={i?.Image} alt="icon" className="md:w-28" />
//                 <div className="text-[#404040] text-center font-semibold text-sm md:text-sm lg:text-base mt-2">
//                   {i?.Type}
//                 </div>
//               </div>
//               <div className="flex justify-center items-start gap-4">
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title1}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description1}
//                   </div>
//                 </div>
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title2}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description2}
//                   </div>
//                 </div>
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title3}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description3}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}

//         {IsActive === "Leased" &&
//           LeasedData?.length > 0 &&
//           LeasedData?.map((i, index) => (
//             <div
//               key={index}
//               className="flex flex-col justify-center items-center  gap-4 bg-[#F5F5F5] rounded-lg shadow-md hover:shadow-lg cursor-pointer p-4"
//             >
//               <div className="flex flex-col justify-center items-center">
//                 <img src={i?.Image} alt="icon" className="md:w-28" />
//                 <div className="text-[#404040] text-center font-semibold text-sm md:text-sm lg:text-base mt-2">
//                   {i?.Type}
//                 </div>
//               </div>
//               <div className="flex justify-center items-start gap-4">
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title1}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description1}
//                   </div>
//                 </div>
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title2}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description2}
//                   </div>
//                 </div>
//                 <div className="w-[33%] flex flex-col justify-center items-center gap-2">
//                   <div className="text-[#171717] text-center font-bold text-sm md:text-base lg:text-lg">
//                     {i?.Title3}
//                   </div>
//                   <div className="text-[#525252] text-center font-medium text-sm md:text-sm">
//                     {i?.Description3}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default ProformanceCard;
