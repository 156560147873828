import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import verify from "../../../assets/verify.png";
import MoreBlue from "../../../assets/MoreBlue.png";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";
import Review from "../Review/Review";
import Pagination from "../Pagination/Pagination";
import HeaderImg from "../../../assets/findAgent/headerImg.webp"
import { FaStar } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import ReadMoreText from "../ReadMoreText";
import PaginationNormal from "../Pagination/PaginationNormal";
import a2 from "../../../assets/detail2.png";


const FindAgentCard = (props) => {
  const { AgentData } = props;
  //console.log("🚀 ~ FindAgentCard ~ AgentData:", AgentData)
  let PageSize = 4;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedReviews, setExpandedReviews] = useState([]);
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentData = AgentData.slice(firstPageIndex, lastPageIndex);

  return (
    <>
      <div
        className=""
      // className={`grid place-items-center grid-cols-1 xl:grid-cols-2 gap-8 ${props.style} `} // old style
      >
        {currentData?.length > 0 &&
          currentData?.map((i, index) => {
            const topHeadColor = i?.TopHeadColor
              ? i?.TopHeadColor.includes('#') ? `bg-[${i?.TopHeadColor}]` : `bg-${i?.TopHeadColor}-700`
              : "#FFE512";
            const agentLogoSrc = i?.AgencySmallLogo;
            const agentImageSrc = i?.AgentImage;
            const goldCoastPropsSold = i?.PropertiesSoldAsLeadAgent;
            const medianDaysPrice = i?.MedianDaysPrice;
            const medianDaysAdvertised = i?.MediandaysAdvertised;
            const allSuburbsPropsSold = i?.PropertiesSold;
            const sellerRating = i?.Review[0]?.star;
            // const verifiedReview = i?.Review[0];
            const verifiedReview = i?.VerifiedReview;
            const reviewText = i?.Review[0]?.review;
            const reviewId = i?.Review[0]?._id;
            const recentlySoldData = i?.RecentlySold;
            const isExpanded = expandedReviews.includes(reviewId);
            const truncatedText =
              reviewText?.length >= 245
                ? `${reviewText?.substring(0, 245)}...`
                : reviewText;
            const navigateToAppraisal = () => {
              navigate(`/appraisal/${i?.id}`);
            };
            const navigateToAgentProfile = () => {
              navigate(`/agent-profile/${i?.id}`);
            };
            const navigateToAgencyProfile = () => {
              navigate(`/agency-profile/${i?.AgencyId}`);
            };
            const toggleExpanded = (reviewId) => {
              if (expandedReviews.includes(reviewId)) {
                setExpandedReviews(
                  expandedReviews.filter((id) => id !== reviewId)
                );
              } else {
                setExpandedReviews([...expandedReviews, reviewId]);
              }
            };

            return (
              <>

                {/* new UI component */}
                <div className="mb-5 rounded bg-white" style={{ boxShadow: '0px 1px 3px 0px #00000033' }}>

                  <div className={`px-6 py-2 ${topHeadColor}`}
                    style={{ backgroundColor: topHeadColor }}
                  >
                    {/* <span className="text-white font-bold px-2 py-1">Red white</span> */}
                    <img src={agentLogoSrc} alt="Red white" className="h-5 " />
                  </div>
                  <div className="p-3 md:p-6">
                    <div className="flex gap-4 flex-col md:flex-row md:min-w-[270px]">
                      <div className="flex flex-row md:flex-col lg:flex-row gap-3">
                        <div>
                          <LazyLoadImage
                            src={agentImageSrc}
                            alt="icon"
                            srcSet={agentImageSrc}
                            onClick={navigateToAgentProfile}
                            loading="lazy"
                            effect="blur"
                            className="w-[98px] h-[98px] aspect-square rounded-full cursor-pointer"
                          />
                        </div>
                        <div>
                          <div className="text-[#333F48] font-light text-lg">
                            {i?.AgentName}
                          </div>
                          <div className="flex lg:items-center flex-row md:flex-col lg:flex-row gap-1">
                            <span className="text-sm lg:text-base font-normal text-[#697684] flex gap-1"> <FaStar className="text-[#FFB200] text-sm" /> 5.0 </span>
                            <span className="text-[#A5ADB5] text-sm lg:text-base">(406 review) </span>
                          </div>
                          <div className="text-[#697684] font-light text-xs flex flex-col gap-1">
                            <p className="">151 reviews in last 12 months</p>
                            <p className="">Selling Principal</p>
                            <p> {i?.AgentRole}</p>
                          </div>
                        </div>
                      </div>


                      <div className="">
                        <div className="flex gap-3 md:gap-4">
                          <div className="flex gap-[10px] md:gap-4 w-full">
                            <div className="bg-[#F7F8F9] p-3 md:p-4 w-full md:w-[364px]">
                              <div className="md:italic flex text-start w-full text-[#697684]">
                                GOLD COAST
                              </div>
                              <div className="flex justify-between mt-4">
                                <div className="flex flex-col justify-center items-center text-center">
                                  <div className="text-[#333F48] font-normal text-2xl ">
                                    {goldCoastPropsSold}
                                  </div>
                                  <div className="text-[#333F48] font-light text-xs mt-1 max-w-24">
                                    Properties sold (as lead agent)
                                  </div>
                                </div>
                                <div className="flex flex-col justify-center items-center text-center">
                                  <div className="text-[#333F48] font-normal text-2xl">
                                    {`${String(medianDaysPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                  </div>
                                  <div className="text-[#333F48] font-light text-xs mt-1 max-w-24">
                                    Median sold price
                                  </div>
                                </div>
                                <div className="flex-col justify-center items-center text-center hidden md:flex">
                                  <div className="text-[#333F48] font-normal text-2xl">
                                    {medianDaysAdvertised}
                                  </div>
                                  <div className="text-[#333F48] font-light text-xs mt-1 max-w-24">
                                    Median days advertised
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col justify-start items-center bg-[#F7F8F9] p-2 md:p-4 text-center w-[126px] sm:w-full md:w-auto">
                              <div className="text-[#697684] text-center font-light text-sm">
                                ALL SUBURBS
                              </div>
                              <div className="text-[#333F48] font-normal text-2xl mt-[18px]">
                                {allSuburbsPropsSold}
                              </div>
                              <div className="text-[#333F48] font-light text-xs mt-1 max-w-20">
                                Properties sold
                              </div>
                            </div>
                          </div>


                          {/* button section Desktop view*/}
                          <div className="w-full  max-w-[290px] hidden md:block">
                            <button
                              className="w-full text-sm xl:text-base font-light border bg-[#E4002B] text-white hover:bg-white  hover:text-[#E4002B] hover:border-[#E4002B] py-4 px-3 rounded"
                              onClick={navigateToAppraisal}
                            >
                              Request a free market appraisal
                            </button>

                            <button
                              className="w-full text-sm xl:text-base font-light border border-[#333F48] py-4 px-3 rounded mt-4"
                              onClick={navigateToAppraisal}
                            >
                              Add to compare
                            </button>
                          </div>
                        </div>
                        {/* Verify review section */}
                        <div className="bg-[#F7F8F9] p-4 mt-4">
                          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                            <div className="flex gap-1">
                              <FaStar className="text-[#FFB200] text-sm" />
                              <FaStar className="text-[#FFB200] text-sm" />
                              <FaStar className="text-[#FFB200] text-sm" />
                              <FaStar className="text-[#FFB200] text-sm" />
                              <FaStar className="text-[#FFB200] text-sm" />
                              <p className="text-base font-light text-[#333F48] pb-0 ml-2">Rating from a seller</p>
                            </div>
                            <h3 className="text-xs font-normal text-[#333F48] flex"> <MdVerified size={16} />  Verified review</h3>
                          </div>
                          <div className="max-w-3xl mt-[14px]">
                            <ReadMoreText text={verifiedReview} />
                          </div>
                        </div>

                        {/* button section mobile view*/}

                        <div className="w-full flex md:hidden gap-2 mt-6">
                          <button
                            className="w-full text-sm xl:text-base font-light border bg-[#E4002B] text-white hover:bg-white  hover:text-[#E4002B] hover:border-[#E4002B] py-4 px-2 rounded"
                            onClick={navigateToAppraisal}
                          >
                            Request a free market appraisal
                          </button>
                          <button
                            className="w-full text-sm xl:text-base font-light border border-[#333F48] py-4 px-3 rounded "
                            onClick={navigateToAppraisal}
                          >
                            Add to compare
                          </button>
                        </div>

                        {recentlySoldData?.length > 0 && (
                          <div className="flex gap-3 md:gap-4 mt-6 md:mt-12 ">
                            {/* video section and once verify below video src */}
                            <div>
                              <div className="text-[#687686] font-medium text-[10px] md:text-xs py-1 uppercase">
                                {"agent video"}
                              </div>
                              <div>
                                <img
                                  src={a2}
                                  alt="icon"
                                  className="w-[118px] h-[68px] aspect-square cursor-pointer"
                                // onClick={() =>
                                //   navigate(`/property-house/${d?._id}`)
                                // }
                                />
                              </div>
                            </div>
                            {/* images section */}
                            <div className="w-full overflow-x-auto scrollBarHidden">
                              <div className="text-[#687686] font-medium text-[10px] md:text-xs py-1 uppercase">
                                RECENTLY SOLD
                              </div>
                              <div className="flex gap-4">
                                {recentlySoldData?.slice(0, 5)?.map((d, index) => (
                                  <div key={index} className="relative min-w-[120px]">
                                    <div className="absolute bottom-0 left-0 bg-[#FFB200] text-white text-xs py-[2px] px-2" style={{ borderRadius: "0 0 0 4px" }}>Sold</div>
                                    <img
                                      src={d?.frontPageImg}
                                      // src={a2}
                                      alt="icon"
                                      className="w-[118px] h-[68px] aspect-square cursor-pointer rounded"
                                      onClick={() =>
                                        navigate(`/property-house/${d?._id}`)
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>


                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* <<----- Paginationp ----->> */}
      <div className="flex justify-center items-center pb-4">
        <PaginationNormal
          currentPage={currentPage}
          totalCount={AgentData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default FindAgentCard;
