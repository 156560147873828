import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const Googlemap2 = (props) => {
  const { location } = props;
  const [mapCenter, setMapCenter] = useState({ lat: 4.624335, lng: -74.063644 });

  useEffect(() => {
    if (location?.length > 0) {
      const randomLocation = location[Math.floor(Math.random() * location.length)];
      setMapCenter({
        lat: randomLocation?.location?.coordinates?.[1],
        lng: randomLocation?.location?.coordinates?.[0],
      });
    }
  }, [location]);

  const displayMarkers = () => {
    return location?.map((store, index) => (
      <Marker
        key={index}
        position={{
          lat: store?.location?.coordinates?.[1],
          lng: store?.location?.coordinates?.[0],
        }}
        icon={{
          url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g filter="url(#filter0_d_2604_38689)">
        <rect x="3.2998" y="1.90039" width="18" height="18" rx="9" fill="#E4002B" />
        <rect x="3.7998" y="2.40039" width="17" height="17" rx="8.5" stroke="white" />
      </g>
      <defs>
        <filter id="filter0_d_2604_38689" x="0.299805" y="0.900391" width="24" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2604_38689" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2604_38689" result="shape" />
        </filter>
      </defs>
    </svg>`),
          scaledSize: new props.google.maps.Size(30, 30)
        }}
      />
    ));
  };

  return (
    <Map
      google={props.google}
      zoom={7}
      initialCenter={mapCenter}
      center={mapCenter}
    >
      {displayMarkers()}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(Googlemap2);
